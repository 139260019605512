import { t } from './translator';
import Activity from '../enums/activity';
import Equation from '../enums/equation';

const equationNames = Object.keys(Equation).map((e) => t(`form.${e}`));
const activityNames = Object.keys(Activity).map((e) => t(`form.${e}`));

const isNumber = value => !isNaN(value) && !isNaN(Number(value));
const isBetween = (value, label, min, max, required = false) => {
  if (!value && required && value !== 0) {
    return t('isRequired', { label });
  }

  if (value) {
    if (!isNumber(value)) {
      return t('mustBeNumber', { label });
    }

    if (max !== null && value > max) {
      return t('lessThanEqual', { label, max });
    }

    if (min !== null && value < min) {
      return t('greaterThanEqual', { label, min });
    }
  }
};

export const exerciseValidation = ({ days, duration, value, mets, label }) => {
  const errors = {};
  const values = { days, duration, value, mets, label };

  let error = isBetween(days, t('daysLabel'), 0, 7, true);
  if (error) {
    errors.days = error;
  } else {
    values.days = Number(days);
  }

  error = isBetween(duration, t('durationLabel'), 0, 1440, true);
  if (error) {
    errors.duration = error;
  } else {
    values.duration = Number(duration);
  }

  error = isBetween(mets, t('metsLabel'), 0, null, true);
  if (error) {
    errors.mets = error;
  } else {
    values.mets = Number(mets);
  }

  if (!value) {
    errors.value = t('valueIsRequired');
  }

  if (!label) {
    errors.label = t('labelIsRequired');
  }

  if (Object.keys(errors).length) {
    return { errors };
  }

  return { values };
};

export const profileValidation = profile => {
  const {
    activity,
    age,
    bodyFat,
    dailyCalories,
    equation,
    gender,
    goalBodyFat,
    goalDate,
    goalWeight,
    startWeight,
    height,
    name,
    startDate,
    weeklyPercentLoss,
    weeklyPoundLoss,
    weight
  } = profile;
  const errors = {};
  const values = profile;

  if (activity && !Object.keys(Activity).includes(activity)) {
    errors.activity = t('errors.activity', { names: activityNames.join(', ') });
  }

  if (equation && !Object.keys(Equation).includes(equation)) {
    errors.equation = t('errors.equation', { names: equationNames.join(', ') });
  }

  if (!gender) {
    errors.gender = t('errors.genderRequired');
  } else if (!['male', 'female'].includes(gender)) {
    errors.gender = t('errors.gender');
  }

  if (!name) {
    errors.name = t('errors.profileName');
  }

  if (goalDate && isNaN(new Date(goalDate).getTime())) {
    errors.goalDate = t('errors.goalDateInvalid');
  } else if (goalDate && new Date() > new Date(goalDate)) {
    errors.goalDate = t('errors.goalDateAfterToday');
  }

  if (startDate && isNaN(new Date(startDate).getTime())) {
    errors.startDate = t('errors.startDateInvalid');
  }

  const numbers = [
    { key: 'age', label: t('errors.age'), required: true, min: 0, max: 150, value: age },
    { key: 'bodyFat', label: t('errors.bodyFat'), min: 0, max: 100, value: bodyFat },
    {
      key: 'dailyCalories',
      label: t('errors.dailyCalories'),
      min: 0,
      max: null,
      value: dailyCalories
    },
    { key: 'goalBodyFat', label: t('errors.goalBodyFat'), min: 0, max: 100, value: goalBodyFat },
    { key: 'goalWeight', label: t('errors.goalWeight'), min: 0, max: null, value: goalWeight },
    { key: 'startWeight', label: t('errors.startWeight'), min: 0, max: null, value: startWeight },
    { key: 'height', label: t('errors.height'), required: true, min: 0, max: null, value: height },
    {
      key: 'weeklyPercentLoss',
      label: t('errors.weeklyPercentLoss'),
      min: 0,
      max: 100,
      value: weeklyPercentLoss
    },
    {
      key: 'weeklyPoundLoss',
      label: t('errors.weeklyPoundLoss'),
      min: 0,
      max: null,
      value: weeklyPoundLoss
    },
    { key: 'weight', label: t('errors.weight'), required: true, min: 0, max: null, value: weight }
  ];

  for (const item of numbers) {
    const error = isBetween(item.value, item.label, item.min, item.max, item.required);
    if (error) {
      errors[item.key] = error;
    } else {
      values[item.key] = Number(item.value);
    }
  }

  if (Object.keys(errors).length) {
    return { errors };
  }

  return { values };
};
