// https://cdn-links.lww.com/permalink/mss/a/mss_43_8_2011_06_13_ainsworth_202093_sdc1.pdf
export const activities = [
  { value: '01003', mets: 14.0, category: 'Bicycling', label: 'Bicycling, mountain, uphill, vigorous' },
  { value: '01004', mets: 16.0, category: 'Bicycling', label: 'Bicycling, mountain, competitive, racing' },
  { value: '01008', mets: 8.5, category: 'Bicycling', label: 'Bicycling, BMX' },
  { value: '01009', mets: 8.5, category: 'Bicycling', label: 'Bicycling, mountain, general' },
  { value: '01010', mets: 4.0, category: 'Bicycling', label: 'Bicycling, <10 mph, leisure, to work or for pleasure (Taylor Code 115)' },
  { value: '01011', mets: 6.8, category: 'Bicycling', label: 'Bicycling, to/from work, self selected pace' },
  { value: '01013', mets: 5.8, category: 'Bicycling', label: 'Bicycling, on dirt or farm road, moderate pace' },
  { value: '01015', mets: 7.5, category: 'Bicycling', label: 'Bicycling, general' },
  { value: '01018', mets: 3.5, category: 'Bicycling', label: 'Bicycling, leisure, 5.5 mph' },
  { value: '01019', mets: 5.8, category: 'Bicycling', label: 'Bicycling, leisure, 9.4 mph' },
  { value: '01020', mets: 6.8, category: 'Bicycling', label: 'Bicycling, 10-11.9 mph, leisure, slow, light effort' },
  { value: '01030', mets: 8.0, category: 'Bicycling', label: 'Bicycling, 12-13.9 mph, leisure, moderate effort' },
  { value: '01040', mets: 10.0, category: 'Bicycling', label: 'Bicycling, 14-15.9 mph, racing or leisure, fast, vigorous effort' },
  { value: '01050', mets: 12.0, category: 'Bicycling', label: 'Bicycling, 16-19 mph, racing/not drafting or > 19 mph drafting, very fast, racing general' },
  { value: '01060', mets: 15.8, category: 'Bicycling', label: 'Bicycling, > 20 mph, racing, not drafting' },
  { value: '01065', mets: 8.5, category: 'Bicycling', label: 'Bicycling, 12 mph, seated, hands on brake hoods or bar drops, 80 rpm' },
  { value: '01066', mets: 9.0, category: 'Bicycling', label: 'Bicycling, 12 mph, standing, hands on brake hoods, 60 rpm' },
  { value: '01070', mets: 5.0, category: 'Bicycling', label: 'Unicycling' },
  { value: '02001', mets: 2.3, category: 'Conditioning Exercise', label: 'Activity promoting video game (e.g., Wii Fit), light effort (e.g., balance, yoga)' },
  { value: '02003', mets: 3.8, category: 'Conditioning Exercise', label: 'Activity promoting video game (e.g., Wii Fit), moderate effort (e.g., aerobic, resistance)' },
  { value: '02005', mets: 7.2, category: 'Conditioning Exercise', label: 'Activity promoting video/arcade game (e.g., Exergaming, Dance Dance Revolution), vigorous effort' },
  { value: '02008', mets: 5.0, category: 'Conditioning Exercise', label: 'Army type obstacle course exercise, boot camp training program' },
  { value: '02010', mets: 7.0, category: 'Conditioning Exercise', label: 'Bicycling, stationary, general' },
  { value: '02011', mets: 3.5, category: 'Conditioning Exercise', label: 'Bicycling, stationary, 30-50 watts, very light to light effort' },
  { value: '02012', mets: 6.8, category: 'Conditioning Exercise', label: 'Bicycling, stationary, 90-100 watts, moderate to vigorous effort' },
  { value: '02013', mets: 8.8, category: 'Conditioning Exercise', label: 'Bicycling, stationary, 101-160 watts, vigorous effort' },
  { value: '02014', mets: 11.0, category: 'Conditioning Exercise', label: 'Bicycling, stationary, 161-200 watts, vigorous effort' },
  { value: '02015', mets: 14.0, category: 'Conditioning Exercise', label: 'Bicycling, stationary, 201-270 watts, very vigorous effort' },
  { value: '02017', mets: 4.8, category: 'Conditioning Exercise', label: 'Bicycling, stationary, 51-89 watts, light-to-moderate effort' },
  { value: '02019', mets: 8.5, category: 'Conditioning Exercise', label: 'Bicycling, stationary, RPM/Spin bike class' },
  { value: '02020', mets: 8.0, category: 'Conditioning Exercise', label: 'Calisthenics (e.g., push ups, sit ups, pull-ups, jumping jacks), vigorous effort' },
  { value: '02022', mets: 3.8, category: 'Conditioning Exercise', label: 'Calisthenics (e.g., push ups, sit ups, pull-ups, lunges), moderate effort' },
  { value: '02024', mets: 2.8, category: 'Conditioning Exercise', label: 'Calisthenics (e.g., situps, abdominal crunches), light effort' },
  { value: '02030', mets: 3.5, category: 'Conditioning Exercise', label: 'Calisthenics, light or moderate effort, general (e.g., back exercises), going up & down from floor (Taylor Code 150)' },
  { value: '02035', mets: 4.3, category: 'Conditioning Exercise', label: 'Circuit training, moderate effort' },
  { value: '02040', mets: 8.0, category: 'Conditioning Exercise', label: 'Circuit training, including kettlebells, some aerobic movement with minimal rest, general, vigorous intensity' },
  { value: '02045', mets: 3.5, category: 'Conditioning Exercise', label: 'Curves (TM) exercise routines in women' },
  { value: '02048', mets: 5.0, category: 'Conditioning Exercise', label: 'Elliptical trainer, moderate effort' },
  { value: '02050', mets: 6.0, category: 'Conditioning Exercise', label: 'Resistance training (weight lifting, free weight, nautilus or universal), power lifting or body building, vigorous effort (Taylor Code 210)' },
  { value: '02052', mets: 5.0, category: 'Conditioning Exercise', label: 'Resistance (weight) training, squats , slow or explosive effort' },
  { value: '02054', mets: 3.5, category: 'Conditioning Exercise', label: 'Resistance (weight) training, multiple exercises, 8-15 repetitions at varied resistance' },
  { value: '02060', mets: 5.5, category: 'Conditioning Exercise', label: 'Health club exercise, general (Taylor Code 160)' },
  { value: '02061', mets: 5.0, category: 'Conditioning Exercise', label: 'Health club exercise classes, general, gym/weight training combined in one visit' },
  { value: '02062', mets: 7.8, category: 'Conditioning Exercise', label: 'Health club exercise, conditioning classes' },
  { value: '02064', mets: 3.8, category: 'Conditioning Exercise', label: 'Home exercise, general' },
  { value: '02065', mets: 9.0, category: 'Conditioning Exercise', label: 'Stair-treadmill ergometer, general' },
  { value: '02068', mets: 12.3, category: 'Conditioning Exercise', label: 'Rope skipping, general' },
  { value: '02070', mets: 6.0, category: 'Conditioning Exercise', label: 'Rowing, stationary ergometer, general, vigorous effort' },
  { value: '02071', mets: 4.8, category: 'Conditioning Exercise', label: 'Rowing, stationary, general, moderate effort' },
  { value: '02072', mets: 7.0, category: 'Conditioning Exercise', label: 'Rowing, stationary, 100 watts, moderate effort' },
  { value: '02073', mets: 8.5, category: 'Conditioning Exercise', label: 'Rowing, stationary, 150 watts, vigorous effort' },
  { value: '02074', mets: 12.0, category: 'Conditioning Exercise', label: 'Rowing, stationary, 200 watts, very vigorous effort' },
  { value: '02080', mets: 6.8, category: 'Conditioning Exercise', label: 'Ski machine, general' },
  { value: '02085', mets: 11.0, category: 'Conditioning Exercise', label: 'Slide board exercise, general' },
  { value: '02090', mets: 6.0, category: 'Conditioning Exercise', label: 'Slimnastics, jazzercise' },
  { value: '02101', mets: 2.3, category: 'Conditioning Exercise', label: 'Stretching, mild' },
  { value: '02105', mets: 3.0, category: 'Conditioning Exercise', label: 'Pilates, general' },
  { value: '02110', mets: 6.8, category: 'Conditioning Exercise', label: 'Teaching exercise class (e.g., aerobic, water)' },
  { value: '02112', mets: 2.8, category: 'Conditioning Exercise', label: 'Therapeutic exercise ball, Fitball exercise' },
  { value: '02115', mets: 2.8, category: 'Conditioning Exercise', label: 'Upper body exercise, arm ergometer' },
  { value: '02117', mets: 4.3, category: 'Conditioning Exercise', label: 'Upper body exercise, stationary bicycle - Airdyne (arms only) 40 rpm, moderate' },
  { value: '02120', mets: 5.3, category: 'Conditioning Exercise', label: 'Water aerobics, water calisthenics, water exercise' },
  { value: '02135', mets: 1.3, category: 'Conditioning Exercise', label: 'Whirlpool, sitting' },
  { value: '02140', mets: 2.3, category: 'Conditioning Exercise', label: 'Video exercise workouts, TV conditioning programs (e.g., yoga, stretching), light effort' },
  { value: '02143', mets: 4.0, category: 'Conditioning Exercise', label: 'Video exercise workouts, TV conditioning programs (e.g., cardio-resistance), moderate effort' },
  { value: '02146', mets: 6.0, category: 'Conditioning Exercise', label: 'Video exercise workouts, TV conditioning programs (e.g., cardio-resistance), vigorous effort' },
  { value: '02150', mets: 2.5, category: 'Conditioning Exercise', label: 'Yoga, Hatha' },
  { value: '02160', mets: 4.0, category: 'Conditioning Exercise', label: 'Yoga, Power' },
  { value: '02170', mets: 2.0, category: 'Conditioning Exercise', label: 'Yoga, Nadisodhana' },
  { value: '02180', mets: 3.3, category: 'Conditioning Exercise', label: 'Yoga, Surya Namaskar' },
  { value: '02200', mets: 5.3, category: 'Conditioning Exercise', label: 'Native New Zealander physical activities (e.g., Haka Powhiri, Moteatea, Waita Tira, Whakawatea, etc.), general, moderate effort' },
  { value: '02205', mets: 6.8, category: 'Conditioning Exercise', label: 'Native New Zealander physical activities (e.g., Haka, Taiahab), general, vigorous effort' },
  { value: '03010', mets: 5.0, category: 'Dancing', label: 'Ballet, modern, or jazz, general, rehearsal or class' },
  { value: '03012', mets: 6.8, category: 'Dancing', label: 'Ballet, modern, or jazz, performance, vigorous effort' },
  { value: '03014', mets: 4.8, category: 'Dancing', label: 'Tap' },
  { value: '03015', mets: 7.3, category: 'Dancing', label: 'Aerobic, general' },
  { value: '03016', mets: 7.5, category: 'Dancing', label: 'Aerobic, step, with 6 - 8 inch step' },
  { value: '03017', mets: 9.5, category: 'Dancing', label: 'Aerobic, step, with 10 - 12 inch step' },
  { value: '03018', mets: 5.5, category: 'Dancing', label: 'Aerobic, step, with 4-inch step' },
  { value: '03019', mets: 8.5, category: 'Dancing', label: 'Bench step class, general' },
  { value: '03020', mets: 5.0, category: 'Dancing', label: 'Aerobic, low impact' },
  { value: '03021', mets: 7.3, category: 'Dancing', label: 'Aerobic, high impact' },
  { value: '03022', mets: 10.0, category: 'Dancing', label: 'Aerobic dance wearing 10-15 lb weights' },
  { value: '03025', mets: 4.5, category: 'Dancing', label: 'Ethnic or cultural dancing (e.g., Greek, Middle Eastern, hula, salsa, merengue, bamba y plena, flamenco, belly, and swing)' },
  { value: '03030', mets: 5.5, category: 'Dancing', label: 'Ballroom, fast (Taylor Code 125)' },
  { value: '03031', mets: 7.8, category: 'Dancing', label: 'General dancing (e.g., disco, folk, Irish step dancing, line dancing, polka, contra, country)' },
  { value: '03038', mets: 11.3, category: 'Dancing', label: 'Ballroom dancing, competitive, general' },
  { value: '03040', mets: 3.0, category: 'Dancing', label: 'Ballroom, slow (e.g., waltz, foxtrot, slow dancing, samba, tango, 19th century dance, mambo, cha cha)' },
  { value: '03050', mets: 5.5, category: 'Dancing', label: 'Anishinaabe Jingle Dancing' },
  { value: '03060', mets: 3.5, category: 'Dancing', label: 'Caribbean dance (Abakua, Beguine, Bellair, Bongo, Brukin\'s, Caribbean Quadrills, Dinki Mini, Gere, Gumbay, Ibo, Jonkonnu, Kumina, Oreisha, Jambu)' },
  { value: '04001', mets: 3.5, category: 'Fishing and Hunting', label: 'Fishing, general' },
  { value: '04005', mets: 4.5, category: 'Fishing and Hunting', label: 'Fishing, crab fishing' },
  { value: '04007', mets: 4.0, category: 'Fishing and Hunting', label: 'Fishing, catching fish with hands' },
  { value: '04010', mets: 4.3, category: 'Fishing and Hunting', label: 'Fishing related, digging worms, with shovel' },
  { value: '04020', mets: 4.0, category: 'Fishing and Hunting', label: 'Fishing from river bank and walking' },
  { value: '04030', mets: 2.0, category: 'Fishing and Hunting', label: 'Fishing from boat or canoe, sitting' },
  { value: '04040', mets: 3.5, category: 'Fishing and Hunting', label: 'Fishing from river bank, standing (Taylor Code 660)' },
  { value: '04050', mets: 6.0, category: 'Fishing and Hunting', label: 'Fishing in stream, in waders (Taylor Code 670)' },
  { value: '04060', mets: 2.0, category: 'Fishing and Hunting', label: 'Fishing, ice, sitting' },
  { value: '04061', mets: 1.8, category: 'Fishing and Hunting', label: 'Fishing, jog or line, standing, general' },
  { value: '04062', mets: 3.5, category: 'Fishing and Hunting', label: 'Fishing, dip net, setting net and retrieving fish, general' },
  { value: '04063', mets: 3.8, category: 'Fishing and Hunting', label: 'Fishing, set net, setting net and retrieving fish, general' },
  { value: '04064', mets: 3.0, category: 'Fishing and Hunting', label: 'Fishing, fishing wheel, setting net and retrieving fish, general' },
  { value: '04065', mets: 2.3, category: 'Fishing and Hunting', label: 'Fishing with a spear, standing' },
  { value: '04070', mets: 2.5, category: 'Fishing and Hunting', label: 'Hunting, bow and arrow, or crossbow' },
  { value: '04080', mets: 6.0, category: 'Fishing and Hunting', label: 'Hunting, deer, elk, large game (Taylor Code 170)' },
  { value: '04081', mets: 11.3, category: 'Fishing and Hunting', label: 'Hunting large game, dragging carcass' },
  { value: '04083', mets: 4.0, category: 'Fishing and Hunting', label: 'Hunting large marine animals' },
  { value: '04085', mets: 2.5, category: 'Fishing and Hunting', label: 'Hunting large game, from a hunting stand, limited walking' },
  { value: '04086', mets: 2.0, category: 'Fishing and Hunting', label: 'Hunting large game from a car, plane, or boat' },
  { value: '04090', mets: 2.5, category: 'Fishing and Hunting', label: 'Hunting, duck, wading' },
  { value: '04095', mets: 3.0, category: 'Fishing and Hunting', label: 'Hunting, flying fox, squirrel' },
  { value: '04100', mets: 5.0, category: 'Fishing and Hunting', label: 'Hunting, general' },
  { value: '04110', mets: 6.0, category: 'Fishing and Hunting', label: 'Hunting, pheasants or grouse (Taylor Code 680)' },
  { value: '04115', mets: 3.3, category: 'Fishing and Hunting', label: 'Hunting, birds' },
  { value: '04120', mets: 5.0, category: 'Fishing and Hunting', label: 'Hunting, rabbit, squirrel, prairie chick, raccoon, small game (Taylor Code 690)' },
  { value: '04123', mets: 3.3, category: 'Fishing and Hunting', label: 'Hunting, pigs, wild' },
  { value: '04124', mets: 2.0, category: 'Fishing and Hunting', label: 'Trapping game, general' },
  { value: '04125', mets: 9.5, category: 'Fishing and Hunting', label: 'Hunting, hiking with hunting gear' },
  { value: '04130', mets: 2.5, category: 'Fishing and Hunting', label: 'Pistol shooting or trap shooting, standing' },
  { value: '04140', mets: 2.3, category: 'Fishing and Hunting', label: 'Rifle exercises, shooting, lying down' },
  { value: '04145', mets: 2.5, category: 'Fishing and Hunting', label: 'Rifle exercises, shooting, kneeling or standing' },
  { value: '05010', mets: 3.3, category: 'Home Activities', label: 'Cleaning, sweeping carpet or floors, general' },
  { value: '05011', mets: 2.3, category: 'Home Activities', label: 'Cleaning, sweeping, slow, light effort' },
  { value: '05012', mets: 3.8, category: 'Home Activities', label: 'Cleaning, sweeping, slow, moderate effort' },
  { value: '05020', mets: 3.5, category: 'Home Activities', label: 'Cleaning, heavy or major (e.g. wash car, wash windows, clean garage), moderate effort' },
  { value: '05021', mets: 3.5, category: 'Home Activities', label: 'Cleaning, mopping, standing, moderate effort' },
  { value: '05022', mets: 3.2, category: 'Home Activities', label: 'Cleaning windows, washing windows, general' },
  { value: '05023', mets: 2.5, category: 'Home Activities', label: 'Mopping, standing, light effort' },
  { value: '05024', mets: 4.5, category: 'Home Activities', label: 'Polishing floors, standing, walking slowly, using electric polishing machine' },
  { value: '05025', mets: 2.8, category: 'Home Activities', label: 'Multiple household tasks all at once, light effort' },
  { value: '05026', mets: 3.5, category: 'Home Activities', label: 'Multiple household tasks all at once, moderate effort' },
  { value: '05027', mets: 4.3, category: 'Home Activities', label: 'Multiple household tasks all at once, vigorous effort' },
  { value: '05030', mets: 3.3, category: 'Home Activities', label: 'Cleaning, house or cabin, general, moderate effort' },
  { value: '05032', mets: 2.3, category: 'Home Activities', label: 'Dusting or polishing furniture, general' },
  { value: '05035', mets: 3.3, category: 'Home Activities', label: 'Kitchen activity, general, (e.g., cooking, washing dishes, cleaning up), moderate effort' },
  { value: '05040', mets: 2.5, category: 'Home Activities', label: 'Cleaning, general (straightening up, changing linen, carrying out trash, light effort' },
  { value: '05041', mets: 1.8, category: 'Home Activities', label: 'Wash dishes, standing or in general (not broken into stand/walk components)' },
  { value: '05042', mets: 2.5, category: 'Home Activities', label: 'Wash dishes, clearing dishes from table, walking, light effort' },
  { value: '05043', mets: 3.3, category: 'Home Activities', label: 'Vacuuming, general, moderate effort' },
  { value: '05044', mets: 3.0, category: 'Home Activities', label: 'Butchering animals, small' },
  { value: '05045', mets: 6.0, category: 'Home Activities', label: 'Butchering animal, large, vigorous effort' },
  { value: '05046', mets: 2.3, category: 'Home Activities', label: 'Cutting and smoking fish, drying fish or meat' },
  { value: '05048', mets: 4.0, category: 'Home Activities', label: 'Tanning hides, general' },
  { value: '05049', mets: 3.5, category: 'Home Activities', label: 'Cooking or food preparation, moderate effort' },
  { value: '05050', mets: 2.0, category: 'Home Activities', label: 'Cooking or food preparation - standing or sitting or in general (not broken into stand/walk components), manual appliances, light effort' },
  { value: '05051', mets: 2.5, category: 'Home Activities', label: 'Serving food, setting table, implied walking or standing' },
  { value: '05052', mets: 2.5, category: 'Home Activities', label: 'Cooking or food preparation, walking' },
  { value: '05053', mets: 2.5, category: 'Home Activities', label: 'Feeding household animals' },
  { value: '05055', mets: 2.5, category: 'Home Activities', label: 'Putting away groceries (e.g. carrying groceries, shopping without a grocery cart), carrying packages' },
  { value: '05056', mets: 7.5, category: 'Home Activities', label: 'Carrying groceries upstairs' },
  { value: '05057', mets: 3.0, category: 'Home Activities', label: 'Cooking Indian bread on an outside stove' },
  { value: '05060', mets: 2.3, category: 'Home Activities', label: 'Food shopping with or without a grocery cart, standing or walking' },
  { value: '05065', mets: 2.3, category: 'Home Activities', label: 'Non-food shopping, with or without a cart, standing or walking' },
  { value: '05070', mets: 1.8, category: 'Home Activities', label: 'Ironing' },
  { value: '05080', mets: 1.3, category: 'Home Activities', label: 'Knitting, sewing, light effort, wrapping presents, sitting' },
  { value: '05082', mets: 2.8, category: 'Home Activities', label: 'Sewing with a machine' },
  { value: '05090', mets: 2.0, category: 'Home Activities', label: 'Laundry, fold or hang clothes, put clothes in washer or dryer, packing suitcase, washing clothes by hand, implied standing, light effort' },
  { value: '05092', mets: 4.0, category: 'Home Activities', label: 'Laundry, hanging wash, washing clothes by hand, moderate effort' },
  { value: '05095', mets: 2.3, category: 'Home Activities', label: 'Laundry, putting away clothes, gathering clothes to pack, putting away laundry, implied walking' },
  { value: '05100', mets: 3.3, category: 'Home Activities', label: 'Making bed, changing linens' },
  { value: '05110', mets: 5.0, category: 'Home Activities', label: 'Maple syruping/sugar bushing (including carrying buckets, carrying wood)' },
  { value: '05120', mets: 5.8, category: 'Home Activities', label: 'Moving furniture, household items, carrying boxes' },
  { value: '05121', mets: 5.0, category: 'Home Activities', label: 'Moving, lifting light loads' },
  { value: '05125', mets: 4.8, category: 'Home Activities', label: 'Organizing room' },
  { value: '05130', mets: 3.5, category: 'Home Activities', label: 'Scrubbing floors, on hands and knees, scrubbing bathroom, bathtub, moderate effort' },
  { value: '05131', mets: 2.0, category: 'Home Activities', label: 'Scrubbing floors, on hands and knees, scrubbing bathroom, bathtub, light effort' },
  { value: '05132', mets: 6.5, category: 'Home Activities', label: 'Scrubbing floors, on hands and knees, scrubbing bathroom, bathtub, vigorous effort' },
  { value: '05140', mets: 4.0, category: 'Home Activities', label: 'Sweeping garage, sidewalk or outside of house' },
  { value: '05146', mets: 3.5, category: 'Home Activities', label: 'Standing, packing/unpacking boxes, occasional lifting of lightweight household items, loading or unloading items in car, moderate effort' },
  { value: '05147', mets: 3.0, category: 'Home Activities', label: 'Implied walking, putting away household items, moderate effort' },
  { value: '05148', mets: 2.5, category: 'Home Activities', label: 'Watering plants' },
  { value: '05149', mets: 2.5, category: 'Home Activities', label: 'Building a fire inside' },
  { value: '05150', mets: 9.0, category: 'Home Activities', label: 'Moving household items upstairs, carrying boxes or furniture' },
  { value: '05160', mets: 2.0, category: 'Home Activities', label: 'Standing, light effort tasks (pump gas, change light bulb, etc.)' },
  { value: '05165', mets: 3.5, category: 'Home Activities', label: 'Walking, moderate effort tasks, non-cleaning (readying to leave, shut/lock doors, close windows, etc.)' },
  { value: '05170', mets: 2.2, category: 'Home Activities', label: 'Sitting, playing with child(ren), light effort, only active periods' },
  { value: '05171', mets: 2.8, category: 'Home Activities', label: 'Standing, playing with child(ren) light effort, only active periods' },
  { value: '05175', mets: 3.5, category: 'Home Activities', label: 'Walking/running, playing with child(ren), moderate effort, only active periods' },
  { value: '05180', mets: 5.8, category: 'Home Activities', label: 'Walking/running, playing with child(ren), vigorous effort, only active periods' },
  { value: '05181', mets: 3.0, category: 'Home Activities', label: 'Walking and carrying small child, child weighing 15 lbs or more' },
  { value: '05182', mets: 2.3, category: 'Home Activities', label: 'Walking and carrying small child, child weighing less than 15 lbs' },
  { value: '05183', mets: 2.0, category: 'Home Activities', label: 'Standing, holding child' },
  { value: '05184', mets: 2.5, category: 'Home Activities', label: 'Child care, infant, general' },
  { value: '05185', mets: 2.0, category: 'Home Activities', label: 'Child care, sitting/kneeling (e.g., dressing, bathing, grooming, feeding, occasional lifting of child), light effort, general' },
  { value: '05186', mets: 3.0, category: 'Home Activities', label: 'Child care, standing (e.g., dressing, bathing, grooming, feeding, occasional lifting of child), moderate effort' },
  { value: '05188', mets: 1.5, category: 'Home Activities', label: 'Reclining with baby' },
  { value: '05189', mets: 2.0, category: 'Home Activities', label: 'Breastfeeding, sitting or reclining' },
  { value: '05190', mets: 2.5, category: 'Home Activities', label: 'Sit, playing with animals, light effort, only active periods' },
  { value: '05191', mets: 2.8, category: 'Home Activities', label: 'Stand, playing with animals, light effort, only active periods' },
  { value: '05192', mets: 3.0, category: 'Home Activities', label: 'Walk/run, playing with animals, general, light effort, only active periods' },
  { value: '05193', mets: 4.0, category: 'Home Activities', label: 'Walk/run, playing with animals, moderate effort, only active periods' },
  { value: '05194', mets: 5.0, category: 'Home Activities', label: 'Walk/run, playing with animals, vigorous effort, only active periods' },
  { value: '05195', mets: 3.5, category: 'Home Activities', label: 'Standing, bathing dog' },
  { value: '05197', mets: 2.3, category: 'Home Activities', label: 'Animal care, household animals, general' },
  { value: '05200', mets: 4.0, category: 'Home Activities', label: 'Elder care, disabled adult, bathing, dressing, moving into and out of bed, only active periods' },
  { value: '05205', mets: 2.3, category: 'Home Activities', label: 'Elder care, disabled adult, feeding, combing hair, light effort, only active periods' },
  { value: '06010', mets: 3.0, category: 'Home Repair', label: 'Airplane repair' },
  { value: '06020', mets: 4.0, category: 'Home Repair', label: 'Automobile body work' },
  { value: '06030', mets: 3.3, category: 'Home Repair', label: 'Automobile repair, light or moderate effort' },
  { value: '06040', mets: 3.0, category: 'Home Repair', label: 'Carpentry, general, workshop (Taylor Code 620)' },
  { value: '06050', mets: 6.0, category: 'Home Repair', label: 'Carpentry, outside house, installing rain gutters (Taylor Code 640),carpentry, outside house, building a fence' },
  { value: '06052', mets: 3.8, category: 'Home Repair', label: 'Carpentry, outside house, building a fence' },
  { value: '06060', mets: 3.3, category: 'Home Repair', label: 'Carpentry, finishing or refinishing cabinets or furniture' },
  { value: '06070', mets: 6.0, category: 'Home Repair', label: 'Carpentry, sawing hardwood' },
  { value: '06072', mets: 4.0, category: 'Home Repair', label: 'Carpentry, home remodeling tasks, moderate effort' },
  { value: '06074', mets: 2.3, category: 'Home Repair', label: 'Carpentry, home remodeling tasks, light effort' },
  { value: '06080', mets: 5.0, category: 'Home Repair', label: 'Caulking, chinking log cabin' },
  { value: '06090', mets: 4.5, category: 'Home Repair', label: 'Caulking, except log cabin' },
  { value: '06100', mets: 5.0, category: 'Home Repair', label: 'Cleaning gutters' },
  { value: '06110', mets: 5.0, category: 'Home Repair', label: 'Excavating garage' },
  { value: '06120', mets: 5.0, category: 'Home Repair', label: 'Hanging storm windows' },
  { value: '06122', mets: 5.0, category: 'Home Repair', label: 'Hanging sheet rock inside house' },
  { value: '06124', mets: 3.0, category: 'Home Repair', label: 'Hammering nails' },
  { value: '06126', mets: 2.5, category: 'Home Repair', label: 'Home repair, general, light effort' },
  { value: '06127', mets: 4.5, category: 'Home Repair', label: 'Home repair, general, moderate effort' },
  { value: '06128', mets: 6.0, category: 'Home Repair', label: 'Home repair, general, vigorous effort' },
  { value: '06130', mets: 4.5, category: 'Home Repair', label: 'Laying or removing carpet' },
  { value: '06140', mets: 3.8, category: 'Home Repair', label: 'Laying tile or linoleum,repairing appliances' },
  { value: '06144', mets: 3.0, category: 'Home Repair', label: 'Repairing appliances' },
  { value: '06150', mets: 5.0, category: 'Home Repair', label: 'Painting, outside home (Taylor Code 650)' },
  { value: '06160', mets: 3.3, category: 'Home Repair', label: 'Painting inside house,wallpapering, scraping paint' },
  { value: '06165', mets: 4.5, category: 'Home Repair', label: 'Painting, (Taylor Code 630)' },
  { value: '06167', mets: 3.0, category: 'Home Repair', label: 'Plumbing, general' },
  { value: '06170', mets: 3.0, category: 'Home Repair', label: 'Put on and removal of tarp - sailboat' },
  { value: '06180', mets: 6.0, category: 'Home Repair', label: 'Roofing' },
  { value: '06190', mets: 4.5, category: 'Home Repair', label: 'Sanding floors with a power sander' },
  { value: '06200', mets: 4.5, category: 'Home Repair', label: 'Scraping and painting sailboat or powerboat' },
  { value: '06205', mets: 2.0, category: 'Home Repair', label: 'Sharpening tools' },
  { value: '06210', mets: 5.0, category: 'Home Repair', label: 'Spreading dirt with a shovel' },
  { value: '06220', mets: 4.5, category: 'Home Repair', label: 'Washing and waxing hull of sailboat or airplane' },
  { value: '06225', mets: 2.0, category: 'Home Repair', label: 'Washing and waxing car' },
  { value: '06230', mets: 4.5, category: 'Home Repair', label: 'Washing fence, painting fence, moderate effort' },
  { value: '06240', mets: 3.3, category: 'Home Repair', label: 'Wiring, tapping-splicing' },
  { value: '07010', mets: 1.0, category: 'Inactivity Quiet/Light', label: 'Lying quietly and watching television' },
  { value: '07011', mets: 1.3, category: 'Inactivity Quiet/Light', label: 'Lying quietly, doing nothing, lying in bed awake, listening to music (not talking or reading)' },
  { value: '07020', mets: 1.3, category: 'Inactivity Quiet/Light', label: 'Sitting quietly and watching television' },
  { value: '07021', mets: 1.3, category: 'Inactivity Quiet/Light', label: 'Sitting quietly, general' },
  { value: '07022', mets: 1.5, category: 'Inactivity Quiet/Light', label: 'Sitting quietly, fidgeting, general, fidgeting hands' },
  { value: '07023', mets: 1.8, category: 'Inactivity Quiet/Light', label: 'Sitting, fidgeting feet' },
  { value: '07024', mets: 1.3, category: 'Inactivity Quiet/Light', label: 'Sitting, smoking' },
  { value: '07025', mets: 1.5, category: 'Inactivity Quiet/Light', label: 'Sitting, listening to music (not talking or reading) or watching a movie in a theater' },
  { value: '07026', mets: 1.3, category: 'Inactivity Quiet/Light', label: 'Sitting at a desk, resting head in hands' },
  { value: '07030', mets: 0.95, category: 'Inactivity Quiet/Light', label: 'Sleeping' },
  { value: '07040', mets: 1.3, category: 'Inactivity Quiet/Light', label: 'Standing quietly, standing in a line' },
  { value: '07041', mets: 1.8, category: 'Inactivity Quiet/Light', label: 'Standing, fidgeting' },
  { value: '07050', mets: 1.3, category: 'Inactivity Quiet/Light', label: 'Reclining, writing' },
  { value: '07060', mets: 1.3, category: 'Inactivity Quiet/Light', label: 'Reclining, talking or talking on phone' },
  { value: '07070', mets: 1.3, category: 'Inactivity Quiet/Light', label: 'Reclining, reading' },
  { value: '07075', mets: 1.0, category: 'Inactivity Quiet/Light', label: 'Meditating' },
  { value: '08009', mets: 3.3, category: 'Lawn and Garden', label: 'Carrying, loading or stacking wood, loading/unloading or carrying lumber, light-to-moderate effort' },
  { value: '08010', mets: 5.5, category: 'Lawn and Garden', label: 'Carrying, loading or stacking wood, loading/unloading or carrying lumber' },
  { value: '08019', mets: 4.5, category: 'Lawn and Garden', label: 'Chopping wood, splitting logs, moderate effort' },
  { value: '08020', mets: 6.3, category: 'Lawn and Garden', label: 'Chopping wood, splitting logs, vigorous effort' },
  { value: '08025', mets: 3.5, category: 'Lawn and Garden', label: 'Clearing light brush, thinning garden, moderate effort' },
  { value: '08030', mets: 6.3, category: 'Lawn and Garden', label: 'Clearing brush/land, undergrowth, or ground, hauling branches, wheelbarrow chores, vigorous effort' },
  { value: '08040', mets: 5.0, category: 'Lawn and Garden', label: 'Digging sandbox, shoveling sand' },
  { value: '08045', mets: 3.5, category: 'Lawn and Garden', label: 'Digging, spading, filling garden, composting, light-to-moderate effort' },
  { value: '08050', mets: 5.0, category: 'Lawn and Garden', label: 'Digging, spading, filling garden, compositing, (Taylor Code 590)' },
  { value: '08052', mets: 7.8, category: 'Lawn and Garden', label: 'Digging, spading, filling garden, composting, vigorous effort' },
  { value: '08055', mets: 2.8, category: 'Lawn and Garden', label: 'Driving tractor' },
  { value: '08057', mets: 8.3, category: 'Lawn and Garden', label: 'Felling trees, large size' },
  { value: '08058', mets: 5.3, category: 'Lawn and Garden', label: 'Felling trees, small-medium size' },
  { value: '08060', mets: 5.8, category: 'Lawn and Garden', label: 'Gardening with heavy power tools, tilling a garden, chain saw' },
  { value: '08065', mets: 2.3, category: 'Lawn and Garden', label: 'Gardening, using containers, older adults > 60 years' },
  { value: '08070', mets: 4.0, category: 'Lawn and Garden', label: 'Irrigation channels, opening and closing ports' },
  { value: '08080', mets: 6.3, category: 'Lawn and Garden', label: 'Laying crushed rock' },
  { value: '08090', mets: 5.0, category: 'Lawn and Garden', label: 'Laying sod' },
  { value: '08095', mets: 5.5, category: 'Lawn and Garden', label: 'Mowing lawn, general' },
  { value: '08100', mets: 2.5, category: 'Lawn and Garden', label: 'Mowing lawn, riding mower (Taylor Code 550)' },
  { value: '08110', mets: 6.0, category: 'Lawn and Garden', label: 'Mowing lawn, walk, hand mower (Taylor Code 570)' },
  { value: '08120', mets: 5.0, category: 'Lawn and Garden', label: 'Mowing lawn, walk, power mower, moderate or vigorous effort' },
  { value: '08125', mets: 4.5, category: 'Lawn and Garden', label: 'Mowing lawn, power mower, light or moderate effort (Taylor Code 590)' },
  { value: '08130', mets: 2.5, category: 'Lawn and Garden', label: 'Operating snow blower, walking' },
  { value: '08135', mets: 2.0, category: 'Lawn and Garden', label: 'Planting, potting, transplanting seedlings or plants, light effort' },
  { value: '08140', mets: 4.3, category: 'Lawn and Garden', label: 'Planting seedlings, shrub, stooping, moderate effort' },
  { value: '08145', mets: 4.3, category: 'Lawn and Garden', label: 'Planting crops or garden, stooping, moderate effort' },
  { value: '08150', mets: 4.5, category: 'Lawn and Garden', label: 'Planting trees' },
  { value: '08160', mets: 3.8, category: 'Lawn and Garden', label: 'Raking lawn or leaves, moderate effort' },
  { value: '08165', mets: 4.0, category: 'Lawn and Garden', label: 'Raking lawn (Taylor Code 600)' },
  { value: '08170', mets: 4.0, category: 'Lawn and Garden', label: 'Raking roof with snow rake' },
  { value: '08180', mets: 3.0, category: 'Lawn and Garden', label: 'Riding snow blower' },
  { value: '08190', mets: 4.0, category: 'Lawn and Garden', label: 'Sacking grass, leaves' },
  { value: '08192', mets: 5.5, category: 'Lawn and Garden', label: 'Shoveling dirt or mud' },
  { value: '08195', mets: 5.3, category: 'Lawn and Garden', label: 'Shoveling snow, by hand, moderate effort' },
  { value: '08200', mets: 6.0, category: 'Lawn and Garden', label: 'Shovelling snow, by hand (Taylor Code 610)' },
  { value: '08202', mets: 7.5, category: 'Lawn and Garden', label: 'Shoveling snow, by hand, vigorous effort' },
  { value: '08210', mets: 4.0, category: 'Lawn and Garden', label: 'Trimming shrubs or trees, manual cutter' },
  { value: '08215', mets: 3.5, category: 'Lawn and Garden', label: 'Trimming shrubs or trees, power cutter, using leaf blower, edge, moderate effort' },
  { value: '08220', mets: 3.0, category: 'Lawn and Garden', label: 'Walking, applying fertilizer or seeding a lawn, push applicator' },
  { value: '08230', mets: 1.5, category: 'Lawn and Garden', label: 'Watering lawn or garden, standing or walking' },
  { value: '08239', mets: 3.5, category: 'Lawn and Garden', label: 'Weeding, cultivating garden, light-to-moderate effort' },
  { value: '08240', mets: 4.5, category: 'Lawn and Garden', label: 'Weeding, cultivating garden (Taylor Code 580)' },
  { value: '08241', mets: 5.0, category: 'Lawn and Garden', label: 'Weeding, cultivating garden, using a hoe, moderate-to-vigorous effort' },
  { value: '08245', mets: 3.8, category: 'Lawn and Garden', label: 'Gardening, general, moderate effort' },
  { value: '08246', mets: 3.5, category: 'Lawn and Garden', label: 'Picking fruit off trees, picking fruits/vegetables, moderate effort' },
  { value: '08248', mets: 4.5, category: 'Lawn and Garden', label: 'Picking fruit off trees, gleaning fruits, picking fruits/vegetables, climbing ladder to pick fruit, vigorous effort' },
  { value: '08250', mets: 3.3, category: 'Lawn and Garden', label: 'Implied walking/standing - picking up yard, light, picking flowers or vegetables' },
  { value: '08251', mets: 3.0, category: 'Lawn and Garden', label: 'Walking, gathering gardening tools' },
  { value: '08255', mets: 5.5, category: 'Lawn and Garden', label: 'Wheelbarrow, pushing garden cart or wheelbarrow' },
  { value: '08260', mets: 3.0, category: 'Lawn and Garden', label: 'Yard work, general, light effort' },
  { value: '08261', mets: 4.0, category: 'Lawn and Garden', label: 'Yard work, general, moderate effort' },
  { value: '08262', mets: 6.0, category: 'Lawn and Garden', label: 'Yard work, general, vigorous effort' },
  { value: '09000', mets: 1.5, category: 'Miscellaneous', label: 'Board game playing, sitting' },
  { value: '09005', mets: 2.5, category: 'Miscellaneous', label: 'Casino gambling, standing' },
  { value: '09010', mets: 1.5, category: 'Miscellaneous', label: 'Card playing, sitting' },
  { value: '09013', mets: 1.5, category: 'Miscellaneous', label: 'Chess game, sitting' },
  { value: '09015', mets: 1.5, category: 'Miscellaneous', label: 'Copying documents, standing' },
  { value: '09020', mets: 1.8, category: 'Miscellaneous', label: 'Drawing, writing, painting, standing' },
  { value: '09025', mets: 1.0, category: 'Miscellaneous', label: 'Laughing, sitting' },
  { value: '09030', mets: 1.3, category: 'Miscellaneous', label: 'Sitting, reading, book, newspaper, etc.' },
  { value: '09040', mets: 1.3, category: 'Miscellaneous', label: 'Sitting, writing, desk work, typing' },
  { value: '09045', mets: 1.0, category: 'Miscellaneous', label: 'Sitting, playing traditional video game, computer game' },
  { value: '09050', mets: 1.8, category: 'Miscellaneous', label: 'Standing, talking in person, on the phone, computer, or text messaging, light effort' },
  { value: '09055', mets: 1.5, category: 'Miscellaneous', label: 'Sitting, talking in person, on the phone, computer, or text messaging, light effort' },
  { value: '09060', mets: 1.3, category: 'Miscellaneous', label: 'Sitting, studying, general, including reading and/or writing, light effort' },
  { value: '09065', mets: 1.8, category: 'Miscellaneous', label: 'Sitting, in class, general, including note-taking or class discussion' },
  { value: '09070', mets: 1.8, category: 'Miscellaneous', label: 'Standing, reading' },
  { value: '09071', mets: 2.5, category: 'Miscellaneous', label: 'Standing, miscellaneous' },
  { value: '09075', mets: 1.8, category: 'Miscellaneous', label: 'Sitting, arts and crafts,  carving wood, weaving, spinning wool, light effort' },
  { value: '09080', mets: 3.0, category: 'Miscellaneous', label: 'Sitting, arts and crafts,  carving wood, weaving, spinning wool, moderate effort' },
  { value: '09085', mets: 2.5, category: 'Miscellaneous', label: 'Standing, arts and crafts, sand painting, carving, weaving, light effort' },
  { value: '09090', mets: 3.3, category: 'Miscellaneous', label: 'Standing, arts and crafts, sand painting, carving, weaving, moderate effort' },
  { value: '09095', mets: 3.5, category: 'Miscellaneous', label: 'Standing, arts and crafts, sand painting, carving, weaving, vigorous effort' },
  { value: '09100', mets: 1.8, category: 'Miscellaneous', label: 'Retreat/family reunion activities involving sitting, relaxing, talking, eating' },
  { value: '09101', mets: 3.0, category: 'Miscellaneous', label: 'Retreat/family reunion activities involving playing games with children' },
  { value: '09105', mets: 2.0, category: 'Miscellaneous', label: 'Touring/traveling/vacation involving riding in a vehicle' },
  { value: '09106', mets: 3.5, category: 'Miscellaneous', label: 'Touring/traveling/vacation involving walking' },
  { value: '09110', mets: 2.5, category: 'Miscellaneous', label: 'Camping involving standing, walking, sitting, light-to-moderate effort' },
  { value: '09115', mets: 1.5, category: 'Miscellaneous', label: 'Sitting at a sporting event, spectator' },
  { value: '10010', mets: 1.8, category: 'Music Playing', label: 'Accordion, sitting' },
  { value: '10020', mets: 2.3, category: 'Music Playing', label: 'Cello, sitting' },
  { value: '10030', mets: 2.3, category: 'Music Playing', label: 'Conducting orchestra, standing' },
  { value: '10035', mets: 2.5, category: 'Music Playing', label: 'Double bass, standing' },
  { value: '10040', mets: 3.8, category: 'Music Playing', label: 'Drums, sitting' },
  { value: '10045', mets: 3.0, category: 'Music Playing', label: 'Drumming (e.g., bongo, conga, benbe), moderate, sitting' },
  { value: '10050', mets: 2.0, category: 'Music Playing', label: 'Flute, sitting' },
  { value: '10060', mets: 1.8, category: 'Music Playing', label: 'Horn, standing' },
  { value: '10070', mets: 2.3, category: 'Music Playing', label: 'Piano, sitting' },
  { value: '10074', mets: 2.0, category: 'Music Playing', label: 'Playing musical instruments, general' },
  { value: '10077', mets: 2.0, category: 'Music Playing', label: 'Organ, sitting' },
  { value: '10080', mets: 3.5, category: 'Music Playing', label: 'Trombone, standing' },
  { value: '10090', mets: 1.8, category: 'Music Playing', label: 'Trumpet, standing' },
  { value: '10100', mets: 2.5, category: 'Music Playing', label: 'Violin, sitting' },
  { value: '10110', mets: 1.8, category: 'Music Playing', label: 'Woodwind, sitting' },
  { value: '10120', mets: 2.0, category: 'Music Playing', label: 'Guitar, classical, folk, sitting' },
  { value: '10125', mets: 3.0, category: 'Music Playing', label: 'Guitar, rock and roll band, standing' },
  { value: '10130', mets: 4.0, category: 'Music Playing', label: 'Marching band, baton twirling, walking, moderate pace, general' },
  { value: '10131', mets: 5.5, category: 'Music Playing', label: 'Marching band, playing an instrument, walking, brisk pace, general' },
  { value: '10135', mets: 3.5, category: 'Music Playing', label: 'Marching band, drum major, walking' },
  { value: '11003', mets: 2.3, category: 'Occupation', label: 'Active workstation, treadmill desk, walking' },
  { value: '11006', mets: 3.0, category: 'Occupation', label: 'Airline flight attendant' },
  { value: '11010', mets: 4.0, category: 'Occupation', label: 'Bakery, general, moderate effort' },
  { value: '11015', mets: 2.0, category: 'Occupation', label: 'Bakery, light effort' },
  { value: '11020', mets: 2.3, category: 'Occupation', label: 'Bookbinding' },
  { value: '11030', mets: 6.0, category: 'Occupation', label: 'Building road, driving heavy machinery' },
  { value: '11035', mets: 2.0, category: 'Occupation', label: 'Building road, directing traffic, standing' },
  { value: '11038', mets: 2.5, category: 'Occupation', label: 'Carpentry, general, light effort' },
  { value: '11040', mets: 4.3, category: 'Occupation', label: 'Carpentry, general, moderate effort' },
  { value: '11042', mets: 7.0, category: 'Occupation', label: 'Carpentry, general, heavy or vigorous effort' },
  { value: '11050', mets: 8.0, category: 'Occupation', label: 'Carrying heavy loads (e.g., bricks, tools)' },
  { value: '11060', mets: 8.0, category: 'Occupation', label: 'Carrying moderate loads up stairs, moving boxes 25-49 lbs' },
  { value: '11070', mets: 4.0, category: 'Occupation', label: 'Chambermaid, hotel housekeeper, making bed, cleaning bathroom, pushing cart' },
  { value: '11080', mets: 5.3, category: 'Occupation', label: 'Coal mining, drilling coal, rock' },
  { value: '11090', mets: 5.0, category: 'Occupation', label: 'Coal mining, erecting supports' },
  { value: '11100', mets: 5.5, category: 'Occupation', label: 'Coal mining, general' },
  { value: '11110', mets: 6.3, category: 'Occupation', label: 'Coal mining, shoveling coal' },
  { value: '11115', mets: 2.5, category: 'Occupation', label: 'Cook, chef' },
  { value: '11120', mets: 4.0, category: 'Occupation', label: 'Construction, outside, remodeling, new structures (e.g., roof repair, miscellaneous)' },
  { value: '11125', mets: 2.3, category: 'Occupation', label: 'Custodial work, light effort (e.g., cleaning sink and toilet, dusting, vacuuming, light cleaning)' },
  { value: '11126', mets: 3.8, category: 'Occupation', label: 'Custodial work, moderate effort (e.g., electric buffer, feathering arena floors, mopping, taking out trash, vacuuming)' },
  { value: '11128', mets: 2.0, category: 'Occupation', label: 'Driving delivery truck, taxi, shuttle bus, school bus' },
  { value: '11130', mets: 3.3, category: 'Occupation', label: 'Electrical work (e.g., hook up wire, tapping-splicing)' },
  { value: '11135', mets: 1.8, category: 'Occupation', label: 'Engineer (e.g., mechanical or electrical)' },
  { value: '11145', mets: 7.8, category: 'Occupation', label: 'Farming, vigorous effort (e.g., baling hay, cleaning barn)' },
  { value: '11146', mets: 4.8, category: 'Occupation', label: 'Farming, moderate effort (e.g., feeding animals, chasing cattle by walking and/or horseback, spreading manure, harvesting crops)' },
  { value: '11147', mets: 2.0, category: 'Occupation', label: 'Farming, light effort (e.g., cleaning animal sheds, preparing animal feed)' },
  { value: '11170', mets: 2.8, category: 'Occupation', label: 'Farming, driving tasks (e.g., driving tractor or harvester)' },
  { value: '11180', mets: 3.5, category: 'Occupation', label: 'Farming, feeding small animals' },
  { value: '11190', mets: 4.3, category: 'Occupation', label: 'Farming, feeding cattle, horses' },
  { value: '11191', mets: 4.3, category: 'Occupation', label: 'Farming, hauling water for animals, general hauling water,farming, general hauling water' },
  { value: '11192', mets: 4.5, category: 'Occupation', label: 'Farming, taking care of animals (e.g., grooming, brushing, shearing sheep, assisting with birthing, medical care, branding), general' },
  { value: '11195', mets: 3.8, category: 'Occupation', label: 'Farming, rice, planting, grain milling activities' },
  { value: '11210', mets: 3.5, category: 'Occupation', label: 'Farming, milking by hand, cleaning pails, moderate effort' },
  { value: '11220', mets: 1.3, category: 'Occupation', label: 'Farming, milking by machine, light effort' },
  { value: '11240', mets: 8.0, category: 'Occupation', label: 'Fire fighter, general' },
  { value: '11244', mets: 6.8, category: 'Occupation', label: 'Fire fighter, rescue victim, automobile accident, using pike pole' },
  { value: '11245', mets: 8.0, category: 'Occupation', label: 'Fire fighter, raising and climbing ladder with full gear, simulated fire supression' },
  { value: '11246', mets: 9.0, category: 'Occupation', label: 'Fire fighter, hauling hoses on ground, carrying/hoisting equipment, breaking down walls etc., wearing full gear' },
  { value: '11247', mets: 3.5, category: 'Occupation', label: 'Fishing, commercial, light effort' },
  { value: '11248', mets: 5.0, category: 'Occupation', label: 'Fishing, commercial, moderate effort' },
  { value: '11249', mets: 7.0, category: 'Occupation', label: 'Fishing, commercial, vigorous effort' },
  { value: '11250', mets: 17.5, category: 'Occupation', label: 'Forestry, ax chopping, very fast, 1.25 kg axe, 51 blows/min, extremely vigorous effort' },
  { value: '11260', mets: 5.0, category: 'Occupation', label: 'Forestry, ax chopping, slow, 1.25 kg axe, 19 blows/min, moderate effort' },
  { value: '11262', mets: 8.0, category: 'Occupation', label: 'Forestry, ax chopping, fast, 1.25 kg axe, 35 blows/min, vigorous effort' },
  { value: '11264', mets: 4.5, category: 'Occupation', label: 'Forestry, moderate effort (e.g., sawing wood with power saw, weeding, hoeing)' },
  { value: '11266', mets: 8.0, category: 'Occupation', label: 'Forestry, vigorous effort (e.g., barking, felling, or trimming trees, carrying or stacking logs, planting seeds, sawing lumber by hand)' },
  { value: '11370', mets: 4.5, category: 'Occupation', label: 'Furriery' },
  { value: '11375', mets: 4.0, category: 'Occupation', label: 'Garbage collector, walking, dumping bins into truck' },
  { value: '11378', mets: 1.8, category: 'Occupation', label: 'Hairstylist (e.g., plaiting hair, manicure, make-up artist)' },
  { value: '11380', mets: 7.3, category: 'Occupation', label: 'Horse grooming, including feeding, cleaning stalls, bathing, brushing, clipping, longeing and exercising horses' },
  { value: '11381', mets: 4.3, category: 'Occupation', label: 'Horse, feeding, watering, cleaning stalls, implied walking and lifting loads' },
  { value: '11390', mets: 7.3, category: 'Occupation', label: 'Horse racing, galloping' },
  { value: '11400', mets: 5.8, category: 'Occupation', label: 'Horse racing, trotting' },
  { value: '11410', mets: 3.8, category: 'Occupation', label: 'Horse racing, walking' },
  { value: '11413', mets: 3.0, category: 'Occupation', label: 'Kitchen maid' },
  { value: '11415', mets: 4.0, category: 'Occupation', label: 'Lawn keeper, yard work, general' },
  { value: '11418', mets: 3.3, category: 'Occupation', label: 'Laundry worker' },
  { value: '11420', mets: 3.0, category: 'Occupation', label: 'Locksmith' },
  { value: '11430', mets: 3.0, category: 'Occupation', label: 'Machine tooling (e.g., machining, working sheet metal, machine fitter, operating lathe, welding) light-to-moderate effort' },
  { value: '11450', mets: 5.0, category: 'Occupation', label: 'Machine tooling, operating punch press, moderate effort' },
  { value: '11472', mets: 1.8, category: 'Occupation', label: 'Manager, property' },
  { value: '11475', mets: 2.8, category: 'Occupation', label: 'Manual or unskilled labor, general, light effort' },
  { value: '11476', mets: 4.5, category: 'Occupation', label: 'Manual or unskilled labor, general, moderate effort' },
  { value: '11477', mets: 6.5, category: 'Occupation', label: 'Manual or unskilled labor, general, vigorous effort' },
  { value: '11480', mets: 4.3, category: 'Occupation', label: 'Masonry, concrete, moderate effort' },
  { value: '11482', mets: 2.5, category: 'Occupation', label: 'Masonry, concrete, light effort' },
  { value: '11485', mets: 4.0, category: 'Occupation', label: 'Massage therapist, standing' },
  { value: '11490', mets: 7.5, category: 'Occupation', label: 'Moving, carrying or pushing heavy objects, 75 lbs or more, only active time (e.g., desks, moving van work)' },
  { value: '11495', mets: 12.0, category: 'Occupation', label: 'Skindiving or SCUBA diving as a frogman, Navy Seal' },
  { value: '11500', mets: 2.5, category: 'Occupation', label: 'Operating heavy duty equipment, automated, not driving' },
  { value: '11510', mets: 4.5, category: 'Occupation', label: 'Orange grove work, picking fruit' },
  { value: '11514', mets: 3.3, category: 'Occupation', label: 'Painting,house, furniture, moderate effort' },
  { value: '11516', mets: 3.0, category: 'Occupation', label: 'Plumbing activities' },
  { value: '11520', mets: 2.0, category: 'Occupation', label: 'Printing, paper industry worker, standing' },
  { value: '11525', mets: 2.5, category: 'Occupation', label: 'Police, directing traffic, standing' },
  { value: '11526', mets: 2.5, category: 'Occupation', label: 'Police, driving a squad car, sitting' },
  { value: '11527', mets: 1.3, category: 'Occupation', label: 'Police, riding in a squad car, sitting' },
  { value: '11528', mets: 4.0, category: 'Occupation', label: 'Police, making an arrest, standing' },
  { value: '11529', mets: 2.3, category: 'Occupation', label: 'Postal carrier, walking to deliver mail' },
  { value: '11530', mets: 2.0, category: 'Occupation', label: 'Shoe repair, general' },
  { value: '11540', mets: 7.8, category: 'Occupation', label: 'Shoveling, digging ditches' },
  { value: '11550', mets: 8.8, category: 'Occupation', label: 'Shoveling, more than 16 lbs/minute, deep digging, vigorous effort' },
  { value: '11560', mets: 5.0, category: 'Occupation', label: 'Shoveling, less than 10 lbs/minute, moderate effort' },
  { value: '11570', mets: 6.5, category: 'Occupation', label: 'Shoveling, 10 to 15 lbs/minute, vigorous effort' },
  { value: '11580', mets: 1.5, category: 'Occupation', label: 'Sitting tasks, light effort (e.g., office work, chemistry lab work, computer work, light assembly repair, watch repair, reading, desk work)' },
  { value: '11585', mets: 1.5, category: 'Occupation', label: 'Sitting meetings, light effort, general, and/or with talking involved (e.g., eating at a business meeting)' },
  { value: '11590', mets: 2.5, category: 'Occupation', label: 'Sitting tasks, moderate effort (e.g., pushing heavy levers, riding mower/forklift, crane operation)' },
  { value: '11593', mets: 2.8, category: 'Occupation', label: 'Sitting, teaching stretching or yoga, or light effort exercise class' },
  { value: '11600', mets: 3.0, category: 'Occupation', label: 'Standing tasks, light effort (e.g., bartending, store clerk, assembling, filing, duplicating, librarian, putting up a Christmas tree, standing and talking at work, changing clothes when teaching physical education, standing)' },
  { value: '11610', mets: 3.0, category: 'Occupation', label: 'Standing, light/moderate effort (e.g., assemble/repair heavy parts, welding,stocking parts,auto repair,standing, packing boxes, nursing patient care)' },
  { value: '11615', mets: 4.5, category: 'Occupation', label: 'Standing, moderate effort, lifting items continuously, 10-20 lbs, with limited walking or resting' },
  { value: '11620', mets: 3.5, category: 'Occupation', label: 'Standing, moderate effort, intermittent lifting 50 lbs, hitch/twisting ropes' },
  { value: '11630', mets: 4.5, category: 'Occupation', label: 'Standing, moderate/heavy tasks (e.g., lifting more than 50 lbs, masonry, painting, paper hanging)' },
  { value: '11708', mets: 5.3, category: 'Occupation', label: 'Steel mill, moderate effort (e.g., fettling, forging, tipping molds)' },
  { value: '11710', mets: 8.3, category: 'Occupation', label: 'Steel mill, vigorous effort (e.g., hand rolling, merchant mill rolling, removing slag, tending furnace)' },
  { value: '11720', mets: 2.3, category: 'Occupation', label: 'Tailoring, cutting fabric' },
  { value: '11730', mets: 2.5, category: 'Occupation', label: 'Tailoring, general' },
  { value: '11740', mets: 1.8, category: 'Occupation', label: 'Tailoring, hand sewing' },
  { value: '11750', mets: 2.5, category: 'Occupation', label: 'Tailoring, machine sewing' },
  { value: '11760', mets: 3.5, category: 'Occupation', label: 'Tailoring, pressing' },
  { value: '11763', mets: 2.0, category: 'Occupation', label: 'Tailoring, weaving, light effort (e.g., finishing operations, washing, dyeing, inspecting cloth, counting yards, paperwork)' },
  { value: '11765', mets: 4.0, category: 'Occupation', label: 'Tailoring, weaving, moderate effort (e.g., spinning and weaving operations, delivering boxes of yam to spinners, loading of warp bean, pinwinding, conewinding, warping, cloth cutting)' },
  { value: '11766', mets: 6.5, category: 'Occupation', label: 'Truck driving, loading and unloading truck, tying down load, standing, walking and carrying heavy loads' },
  { value: '11767', mets: 2.0, category: 'Occupation', label: 'Truch, driving delivery truck, taxi, shuttlebus, school bus' },
  { value: '11770', mets: 1.3, category: 'Occupation', label: 'Typing, electric, manual or computer' },
  { value: '11780', mets: 6.3, category: 'Occupation', label: 'Using heavy power tools such as pneumatic tools (e.g., jackhammers, drills)' },
  { value: '11790', mets: 8.0, category: 'Occupation', label: 'Using heavy tools (not power) such as shovel, pick, tunnel bar, spade' },
  { value: '11791', mets: 2.0, category: 'Occupation', label: 'Walking on job, less than 2.0 mph, very slow speed, in office or lab area' },
  { value: '11792', mets: 3.5, category: 'Occupation', label: 'Walking on job, 3.0 mph, in office, moderate speed, not carrying anything' },
  { value: '11793', mets: 4.3, category: 'Occupation', label: 'Walking on job, 3.5 mph, in office, brisk speed, not carrying anything' },
  { value: '11795', mets: 3.5, category: 'Occupation', label: 'Walking on job, 2.5 mph, slow speed and carrying light objects less than 25 lbs' },
  { value: '11796', mets: 3.0, category: 'Occupation', label: 'Walking, gathering things at work, ready to leave' },
  { value: '11797', mets: 3.8, category: 'Occupation', label: 'Walking, 2.5 mph, slow speed, carrying heavy objects more than 25 lbs' },
  { value: '11800', mets: 4.5, category: 'Occupation', label: 'Walking, 3.0 mph, moderately and carrying light objects less than 25 lbs' },
  { value: '11805', mets: 3.5, category: 'Occupation', label: 'Walking, pushing a wheelchair' },
  { value: '11810', mets: 4.8, category: 'Occupation', label: 'Walking, 3.5 mph, briskly and carrying objects less than 25 lbs' },
  { value: '11820', mets: 5.0, category: 'Occupation', label: 'Walking or walk downstairs or standing, carrying objects about 25 to 49 lbs' },
  { value: '11830', mets: 6.5, category: 'Occupation', label: 'Walking or walk downstairs or standing, carrying objects about 50 to 74 lbs' },
  { value: '11840', mets: 7.5, category: 'Occupation', label: 'Walking or walk downstairs or standing, carrying objects about 75 to 99 lbs' },
  { value: '11850', mets: 8.5, category: 'Occupation', label: 'Walking or walk downstairs or standing, carrying objects about 100 lbs or more' },
  { value: '11870', mets: 3.0, category: 'Occupation', label: 'Working in scene shop, theater actor, backstage employee' },
  { value: '12010', mets: 6.0, category: 'Running', label: 'Jog/walk combination (jogging component of less than 10 minutes) (Taylor Code 180)' },
  { value: '12020', mets: 7.0, category: 'Running', label: 'Jogging, general' },
  { value: '12025', mets: 8.0, category: 'Running', label: 'Jogging, in place' },
  { value: '12027', mets: 4.5, category: 'Running', label: 'Jogging, on a mini-tramp' },
  { value: '12029', mets: 6.0, category: 'Running', label: 'Running, 4 mph (13 min/mile)' },
  { value: '12030', mets: 8.3, category: 'Running', label: 'Running, 5 mph (12 min/mile)' },
  { value: '12040', mets: 9.0, category: 'Running', label: 'Running, 5.2 mph (11.5 min/mile)' },
  { value: '12050', mets: 9.8, category: 'Running', label: 'Running, 6 mph (10 min/mile)' },
  { value: '12060', mets: 10.5, category: 'Running', label: 'Running, 6.7 mph (9 min/mile)' },
  { value: '12070', mets: 11.0, category: 'Running', label: 'Running, 7 mph (8.5 min/mile)' },
  { value: '12080', mets: 11.5, category: 'Running', label: 'Running, 7.5 mph (8 min/mile)' },
  { value: '12090', mets: 11.8, category: 'Running', label: 'Running, 8 mph (7.5 min/mile)' },
  { value: '12100', mets: 12.3, category: 'Running', label: 'Running, 8.6 mph (7 min/mile)' },
  { value: '12110', mets: 12.8, category: 'Running', label: 'Running, 9 mph (6.5 min/mile)' },
  { value: '12120', mets: 14.5, category: 'Running', label: 'Running, 10 mph (6 min/mile)' },
  { value: '12130', mets: 16.0, category: 'Running', label: 'Running, 11 mph (5.5 min/mile)' },
  { value: '12132', mets: 19.0, category: 'Running', label: 'Running, 12 mph (5 min/mile)' },
  { value: '12134', mets: 19.8, category: 'Running', label: 'Running, 13 mph (4.6 min/mile)' },
  { value: '12135', mets: 23.0, category: 'Running', label: 'Running, 14 mph (4.3 min/mile)' },
  { value: '12140', mets: 9.0, category: 'Running', label: 'Running, cross country' },
  { value: '12150', mets: 8.0, category: 'Running', label: 'Running, (Taylor code 200)' },
  { value: '12170', mets: 15.0, category: 'Running', label: 'Running, stairs, up' },
  { value: '12180', mets: 10.0, category: 'Running', label: 'Running, on a track, team practice' },
  { value: '12190', mets: 8.0, category: 'Running', label: 'Running, training, pushing a wheelchair or baby carrier' },
  { value: '12200', mets: 13.3, category: 'Running', label: 'Running, marathon' },
  { value: '13000', mets: 2.3, category: 'Self Care', label: 'Getting ready for bed, general, standing' },
  { value: '13009', mets: 1.8, category: 'Self Care', label: 'Sitting on toilet, eliminating while standing or squating' },
  { value: '13010', mets: 1.5, category: 'Self Care', label: 'Bathing, sitting' },
  { value: '13020', mets: 2.5, category: 'Self Care', label: 'Dressing, undressing, standing or sitting' },
  { value: '13030', mets: 1.5, category: 'Self Care', label: 'Eating, sitting' },
  { value: '13035', mets: 2.0, category: 'Self Care', label: 'Talking and eating or eating only, standing' },
  { value: '13036', mets: 1.5, category: 'Self Care', label: 'Taking medication, sitting or standing' },
  { value: '13040', mets: 2.0, category: 'Self Care', label: 'Grooming, washing hands, shaving, brushing teeth, putting on make-up, sitting or standing' },
  { value: '13045', mets: 2.5, category: 'Self Care', label: 'Hairstyling, standing' },
  { value: '13046', mets: 1.3, category: 'Self Care', label: 'Having hair or nails done by someone else, sitting' },
  { value: '13050', mets: 2.0, category: 'Self Care', label: 'Showering, toweling off, standing' },
  { value: '14010', mets: 2.8, category: 'Sexual Activity', label: 'Sexual Activity, active, vigorous effort' },
  { value: '14020', mets: 1.8, category: 'Sexual Activity', label: 'Sexual Activity, general, moderate effort' },
  { value: '14030', mets: 1.3, category: 'Sexual Activity', label: 'Sexual Activity, passive, light effort, kissing, hugging' },
  { value: '15000', mets: 5.5, category: 'Sports', label: 'Alaska Native Games, Eskimo Olympics, general' },
  { value: '15010', mets: 4.3, category: 'Sports', label: 'Archery, non-hunting' },
  { value: '15020', mets: 7.0, category: 'Sports', label: 'Badminton, competitive (Taylor Code 450)' },
  { value: '15030', mets: 5.5, category: 'Sports', label: 'Badminton, social singles and doubles, general' },
  { value: '15040', mets: 8.0, category: 'Sports', label: 'Basketball, game (Taylor Code 490)' },
  { value: '15050', mets: 6.0, category: 'Sports', label: 'Basketball, non-game, general (Taylor Code 480)' },
  { value: '15055', mets: 6.5, category: 'Sports', label: 'Basketball, general' },
  { value: '15060', mets: 7.0, category: 'Sports', label: 'Basketball, officiating (Taylor Code 500)' },
  { value: '15070', mets: 4.5, category: 'Sports', label: 'Basketball, shooting baskets' },
  { value: '15072', mets: 9.3, category: 'Sports', label: 'Basketball, drills, practice' },
  { value: '15075', mets: 7.8, category: 'Sports', label: 'Basketball, wheelchair' },
  { value: '15080', mets: 2.5, category: 'Sports', label: 'Billiards' },
  { value: '15090', mets: 3.0, category: 'Sports', label: 'Bowling (Taylor Code 390)' },
  { value: '15092', mets: 3.8, category: 'Sports', label: 'Bowling, indoor, bowling alley' },
  { value: '15100', mets: 12.8, category: 'Sports', label: 'Boxing, in ring, general' },
  { value: '15110', mets: 5.5, category: 'Sports', label: 'Boxing, punching bag' },
  { value: '15120', mets: 7.8, category: 'Sports', label: 'Boxing, sparring' },
  { value: '15130', mets: 7.0, category: 'Sports', label: 'Broomball' },
  { value: '15135', mets: 5.8, category: 'Sports', label: 'Children\'s games, adults playing (e.g., hopscotch, 4-square, dodgeball, playground apparatus, t-ball, tetherball, marbles, arcade games), moderate effort' },
  { value: '15138', mets: 6.0, category: 'Sports', label: 'Cheerleading, gymnastic moves, competitive' },
  { value: '15140', mets: 4.0, category: 'Sports', label: 'Coaching, football, soccer, basketball, baseball, swimming, etc.' },
  { value: '15142', mets: 8.0, category: 'Sports', label: 'Coaching, actively playing sport with players' },
  { value: '15150', mets: 4.8, category: 'Sports', label: 'Cricket, batting, bowling, fielding' },
  { value: '15160', mets: 3.3, category: 'Sports', label: 'Croquet' },
  { value: '15170', mets: 4.0, category: 'Sports', label: 'Curling' },
  { value: '15180', mets: 2.5, category: 'Sports', label: 'Darts, wall or lawn' },
  { value: '15190', mets: 6.0, category: 'Sports', label: 'Drag racing, pushing or driving a car' },
  { value: '15192', mets: 8.5, category: 'Sports', label: 'Auto racing, open wheel' },
  { value: '15200', mets: 6.0, category: 'Sports', label: 'Fencing' },
  { value: '15210', mets: 8.0, category: 'Sports', label: 'Football, competitive' },
  { value: '15230', mets: 8.0, category: 'Sports', label: 'Football, touch, flag, general (Taylor Code 510)' },
  { value: '15232', mets: 4.0, category: 'Sports', label: 'Football, touch, flag, light effort' },
  { value: '15235', mets: 2.5, category: 'Sports', label: 'Football or baseball, playing catch' },
  { value: '15240', mets: 3.0, category: 'Sports', label: 'Frisbee playing, general' },
  { value: '15250', mets: 8.0, category: 'Sports', label: 'Frisbee, ultimate' },
  { value: '15255', mets: 4.8, category: 'Sports', label: 'Golf, general' },
  { value: '15265', mets: 4.3, category: 'Sports', label: 'Golf, walking, carrying clubs' },
  { value: '15270', mets: 3.0, category: 'Sports', label: 'Golf, miniature, driving range' },
  { value: '15285', mets: 5.3, category: 'Sports', label: 'Golf, walking, pulling clubs' },
  { value: '15290', mets: 3.5, category: 'Sports', label: 'Golf, using power cart (Taylor Code 070)' },
  { value: '15300', mets: 3.8, category: 'Sports', label: 'Gymnastics, general' },
  { value: '15310', mets: 4.0, category: 'Sports', label: 'Hacky sack' },
  { value: '15320', mets: 12.0, category: 'Sports', label: 'Handball, general (Taylor Code 520)' },
  { value: '15330', mets: 8.0, category: 'Sports', label: 'Handball, team' },
  { value: '15335', mets: 4.0, category: 'Sports', label: 'High ropes course, multiple elements' },
  { value: '15340', mets: 3.5, category: 'Sports', label: 'Hang gliding' },
  { value: '15350', mets: 7.8, category: 'Sports', label: 'Hockey, field' },
  { value: '15360', mets: 8.0, category: 'Sports', label: 'Hockey, ice, general' },
  { value: '15362', mets: 10.0, category: 'Sports', label: 'Hockey, ice, competitive' },
  { value: '15370', mets: 5.5, category: 'Sports', label: 'Horseback riding, general' },
  { value: '15375', mets: 4.3, category: 'Sports', label: 'Horse chores, feeding, watering, cleaning stalls, implied walking and lifting loads' },
  { value: '15380', mets: 4.5, category: 'Sports', label: 'Saddling, cleaning, grooming, harnessing and unharnessing horse' },
  { value: '15390', mets: 5.8, category: 'Sports', label: 'Horseback riding, trotting' },
  { value: '15395', mets: 7.3, category: 'Sports', label: 'Horseback riding, canter or gallop' },
  { value: '15400', mets: 3.8, category: 'Sports', label: 'Horseback riding,walking' },
  { value: '15402', mets: 9.0, category: 'Sports', label: 'Horseback riding, jumping' },
  { value: '15408', mets: 1.8, category: 'Sports', label: 'Horse cart, driving, standing or sitting' },
  { value: '15410', mets: 3.0, category: 'Sports', label: 'Horseshoe pitching, quoits' },
  { value: '15420', mets: 12.0, category: 'Sports', label: 'Jai alai' },
  { value: '15425', mets: 5.3, category: 'Sports', label: 'Martial arts, different types, slower pace, novice performers, practice' },
  { value: '15430', mets: 10.3, category: 'Sports', label: 'Martial arts, different types, moderate pace (e.g., judo, jujitsu, karate, kick boxing, tae kwan do, tai-bo, Muay Thai boxing)' },
  { value: '15440', mets: 4.0, category: 'Sports', label: 'Juggling' },
  { value: '15450', mets: 7.0, category: 'Sports', label: 'Kickball' },
  { value: '15460', mets: 8.0, category: 'Sports', label: 'Lacrosse' },
  { value: '15465', mets: 3.3, category: 'Sports', label: 'Lawn bowling, bocce ball, outdoor' },
  { value: '15470', mets: 4.0, category: 'Sports', label: 'Moto-cross, off-road motor sports, all-terrain vehicle, general' },
  { value: '15480', mets: 9.0, category: 'Sports', label: 'Orienteering' },
  { value: '15490', mets: 10.0, category: 'Sports', label: 'Paddleball, competitive' },
  { value: '15500', mets: 6.0, category: 'Sports', label: 'Paddleball, casual, general (Taylor Code 460)' },
  { value: '15510', mets: 8.0, category: 'Sports', label: 'Polo, on horseback' },
  { value: '15520', mets: 10.0, category: 'Sports', label: 'Racquetball, competitive' },
  { value: '15530', mets: 7.0, category: 'Sports', label: 'Racquetball, general (Taylor Code 470)' },
  { value: '15533', mets: 8.0, category: 'Sports', label: 'Rock or mountain climbing (Taylor Code 470) (Formerly code = 17120)' },
  { value: '15535', mets: 7.5, category: 'Sports', label: 'Rock climbing, ascending rock, high difficulty' },
  { value: '15537', mets: 5.8, category: 'Sports', label: 'Rock climbing, ascending or traversing rock, low-to-moderate difficulty' },
  { value: '15540', mets: 5.0, category: 'Sports', label: 'Rock climbing, rappelling' },
  { value: '15542', mets: 4.0, category: 'Sports', label: 'Rodeo sports, general, light effort' },
  { value: '15544', mets: 5.5, category: 'Sports', label: 'Rodeo sports, general, moderate effort' },
  { value: '15546', mets: 7.0, category: 'Sports', label: 'Rodeo sports, general, vigorous effort' },
  { value: '15550', mets: 12.3, category: 'Sports', label: 'Rope jumping, fast pace, 120-160 skips/min' },
  { value: '15551', mets: 11.8, category: 'Sports', label: 'Rope jumping, moderate pace, 100-120 skips/min, general,  2 foot skip, plain bounce' },
  { value: '15552', mets: 8.8, category: 'Sports', label: 'Rope jumping, slow pace, < 100 skips/min, 2 foot skip, rhythm bounce' },
  { value: '15560', mets: 8.3, category: 'Sports', label: 'Rugby, union, team, competitive' },
  { value: '15562', mets: 6.3, category: 'Sports', label: 'Rugby, touch, non-competitive' },
  { value: '15570', mets: 3.0, category: 'Sports', label: 'Shuffleboard' },
  { value: '15580', mets: 5.0, category: 'Sports', label: 'Skateboarding, general, moderate effort' },
  { value: '15582', mets: 6.0, category: 'Sports', label: 'Skateboarding, competitive, vigorous effort' },
  { value: '15590', mets: 7.0, category: 'Sports', label: 'Skating, roller (Taylor Code 360)' },
  { value: '15591', mets: 7.5, category: 'Sports', label: 'Rollerblading, in-line skating, 14.4 km/h (9.0 mph), recreational pace' },
  { value: '15592', mets: 9.8, category: 'Sports', label: 'Rollerblading, in-line skating, 17.7 km/h (11.0 mph), moderate pace, exercise training' },
  { value: '15593', mets: 12.3, category: 'Sports', label: 'Rollerblading, in-line skating, 21.0 to 21.7 km/h (13.0 to 13.6 mph), fast pace, exercise training' },
  { value: '15594', mets: 14.0, category: 'Sports', label: 'Rollerblading, in-line skating, 24.0 km/h (15.0 mph), maximal effort' },
  { value: '15600', mets: 3.5, category: 'Sports', label: 'Skydiving, base jumping, bungee jumping' },
  { value: '15605', mets: 10.0, category: 'Sports', label: 'Soccer, competitive' },
  { value: '15610', mets: 7.0, category: 'Sports', label: 'Soccer, casual, general (Taylor Code 540)' },
  { value: '15620', mets: 5.0, category: 'Sports', label: 'Softball or baseball, fast or slow pitch, general (Taylor Code 440)' },
  { value: '15625', mets: 4.0, category: 'Sports', label: 'Softball, practice' },
  { value: '15630', mets: 4.0, category: 'Sports', label: 'Softball, officiating' },
  { value: '15640', mets: 6.0, category: 'Sports', label: 'Softball,pitching' },
  { value: '15645', mets: 3.3, category: 'Sports', label: 'Sports spectator, very excited, emotional, physically moving' },
  { value: '15650', mets: 12.0, category: 'Sports', label: 'Squash (Taylor Code 530)' },
  { value: '15652', mets: 7.3, category: 'Sports', label: 'Squash, general' },
  { value: '15660', mets: 4.0, category: 'Sports', label: 'Table tennis, ping pong (Taylor Code 410)' },
  { value: '15670', mets: 3.0, category: 'Sports', label: 'Tai chi, qi gong, general' },
  { value: '15672', mets: 1.5, category: 'Sports', label: 'Tai chi, qi gong, sitting, light effort' },
  { value: '15675', mets: 7.3, category: 'Sports', label: 'Tennis, general' },
  { value: '15680', mets: 6.0, category: 'Sports', label: 'Tennis, doubles (Taylor Code 430)' },
  { value: '15685', mets: 4.5, category: 'Sports', label: 'Tennis, doubles' },
  { value: '15690', mets: 8.0, category: 'Sports', label: 'Tennis, singles (Taylor Code 420)' },
  { value: '15695', mets: 5.0, category: 'Sports', label: 'Tennis, hitting balls, non-game play, moderate effort' },
  { value: '15700', mets: 3.5, category: 'Sports', label: 'Trampoline, recreational' },
  { value: '15702', mets: 4.5, category: 'Sports', label: 'Trampoline, competitive' },
  { value: '15710', mets: 4.0, category: 'Sports', label: 'Volleyball (Taylor Code 400)' },
  { value: '15711', mets: 6.0, category: 'Sports', label: 'Volleyball, competitive, in gymnasium' },
  { value: '15720', mets: 3.0, category: 'Sports', label: 'Volleyball, non-competitive, 6 - 9 member team, general' },
  { value: '15725', mets: 8.0, category: 'Sports', label: 'Volleyball, beach, in sand' },
  { value: '15730', mets: 6.0, category: 'Sports', label: 'Wrestling (one match = 5 minutes)' },
  { value: '15731', mets: 7.0, category: 'Sports', label: 'Wallyball, general' },
  { value: '15732', mets: 4.0, category: 'Sports', label: 'Track and field (e.g., shot, discus, hammer throw)' },
  { value: '15733', mets: 6.0, category: 'Sports', label: 'Track and field (e.g., high jump, long jump, triple jump, javelin, pole vault)' },
  { value: '15734', mets: 10.0, category: 'Sports', label: 'Track and field (e.g., steeplechase, hurdles)' },
  { value: '16010', mets: 2.5, category: 'Transportation', label: 'Automobile or light truck (not a semi) driving' },
  { value: '16015', mets: 1.3, category: 'Transportation', label: 'Riding in a car or truck' },
  { value: '16016', mets: 1.3, category: 'Transportation', label: 'Riding in a bus or train' },
  { value: '16020', mets: 1.8, category: 'Transportation', label: 'Flying airplane or helicopter' },
  { value: '16030', mets: 3.5, category: 'Transportation', label: 'Motor scooter, motorcycle' },
  { value: '16035', mets: 6.3, category: 'Transportation', label: 'Pulling rickshaw' },
  { value: '16040', mets: 6.0, category: 'Transportation', label: 'Pushing plane in and out of hangar' },
  { value: '16050', mets: 2.5, category: 'Transportation', label: 'Truck, semi, tractor, > 1 ton, or bus, driving' },
  { value: '16060', mets: 3.5, category: 'Transportation', label: 'Walking for transportation, 2.8-3.2 mph, level, moderate pace, firm surface' },
  { value: '17010', mets: 7.0, category: 'Walking', label: 'Backpacking (Taylor Code 050)' },
  { value: '17012', mets: 7.8, category: 'Walking', label: 'Backpacking, hiking or organized walking with a daypack' },
  { value: '17020', mets: 5.0, category: 'Walking', label: 'Carrying 15 pound load (e.g. suitcase), level ground or downstairs' },
  { value: '17021', mets: 2.3, category: 'Walking', label: 'Carrying 15 lb child, slow walking' },
  { value: '17025', mets: 8.3, category: 'Walking', label: 'Carrying load upstairs, general' },
  { value: '17026', mets: 5.0, category: 'Walking', label: 'Carrying 1 to 15 lb load, upstairs' },
  { value: '17027', mets: 6.0, category: 'Walking', label: 'Carrying 16 to 24 lb load, upstairs' },
  { value: '17028', mets: 8.0, category: 'Walking', label: 'Carrying 25 to 49 lb load, upstairs' },
  { value: '17029', mets: 10.0, category: 'Walking', label: 'Carrying 50 to 74 lb load, upstairs' },
  { value: '17030', mets: 12.0, category: 'Walking', label: 'Carrying > 74 lb load, upstairs' },
  { value: '17031', mets: 3.5, category: 'Walking', label: 'Loading /unloading a car, implied walking' },
  { value: '17033', mets: 6.3, category: 'Walking', label: 'Climbing hills, no load' },
  { value: '17035', mets: 6.5, category: 'Walking', label: 'Climbing hills with 0 to 9 lb load' },
  { value: '17040', mets: 7.3, category: 'Walking', label: 'Climbing hills with 10 to 20 lb load' },
  { value: '17050', mets: 8.3, category: 'Walking', label: 'Climbing hills with 21 to 42 lb load' },
  { value: '17060', mets: 9.0, category: 'Walking', label: 'Climbing hills with 42+ lb load' },
  { value: '17070', mets: 3.5, category: 'Walking', label: 'Descending stairs' },
  { value: '17080', mets: 6.0, category: 'Walking', label: 'Hiking, cross country (Taylor Code 040)' },
  { value: '17082', mets: 5.3, category: 'Walking', label: 'Hiking or walking at a normal pace through fields and hillsides' },
  { value: '17085', mets: 2.5, category: 'Walking', label: 'Bird watching, slow walk' },
  { value: '17088', mets: 4.5, category: 'Walking', label: 'Marching, moderate speed, military, no pack' },
  { value: '17090', mets: 8.0, category: 'Walking', label: 'Marching rapidly, military, no pack' },
  { value: '17100', mets: 4.0, category: 'Walking', label: 'Pushing or pulling stroller with child or walking with children, 2.5 to 3.1 mph' },
  { value: '17105', mets: 3.8, category: 'Walking', label: 'Pushing a wheelchair, non-occupational' },
  { value: '17110', mets: 6.5, category: 'Walking', label: 'Race walking' },
  { value: '17130', mets: 8.0, category: 'Walking', label: 'Stair climbing, using or climbing up ladder (Taylor Code 030)' },
  { value: '17133', mets: 4.0, category: 'Walking', label: 'Stair climbing, slow pace' },
  { value: '17134', mets: 8.8, category: 'Walking', label: 'Stair climbing, fast pace' },
  { value: '17140', mets: 5.0, category: 'Walking', label: 'Using crutches' },
  { value: '17150', mets: 2.0, category: 'Walking', label: 'Walking, household' },
  { value: '17151', mets: 2.0, category: 'Walking', label: 'Walking, less than 2.0 mph, level, strolling, very slow' },
  { value: '17152', mets: 2.8, category: 'Walking', label: 'Walking, 2.0 mph, level, slow pace, firm surface' },
  { value: '17160', mets: 3.5, category: 'Walking', label: 'Walking for pleasure (Taylor Code 010)' },
  { value: '17161', mets: 2.5, category: 'Walking', label: 'Walking from house to car or bus, from car or bus to go places, from car or bus to and from the worksite' },
  { value: '17162', mets: 2.5, category: 'Walking', label: 'Walking to neighbor\'s house or family\'s house for social reasons' },
  { value: '17165', mets: 3.0, category: 'Walking', label: 'Walking the dog' },
  { value: '17170', mets: 3.0, category: 'Walking', label: 'Walking, 2.5 mph, level, firm surface' },
  { value: '17180', mets: 3.3, category: 'Walking', label: 'Walking, 2.5 mph, downhill' },
  { value: '17190', mets: 3.5, category: 'Walking', label: 'Walking, 2.8 to 3.2 mph, level, moderate pace, firm surface' },
  { value: '17200', mets: 4.3, category: 'Walking', label: 'Walking, 3.5 mph, level, brisk, firm surface, walking for exercise' },
  { value: '17210', mets: 5.3, category: 'Walking', label: 'Walking, 2.9 to 3.5 mph, uphill, 1 to 5% grade' },
  { value: '17211', mets: 8.0, category: 'Walking', label: 'Walking, 2.9 to 3.5 mph, uphill, 6% to 15% grade' },
  { value: '17220', mets: 5.0, category: 'Walking', label: 'Walking, 4.0 mph, level, firm surface, very brisk pace' },
  { value: '17230', mets: 7.0, category: 'Walking', label: 'Walking, 4.5 mph, level, firm surface, very, very brisk' },
  { value: '17231', mets: 8.3, category: 'Walking', label: 'Walking, 5.0 mph, level, firm surface' },
  { value: '17235', mets: 9.8, category: 'Walking', label: 'Walking, 5.0 mph, uphill, 3% grade' },
  { value: '17250', mets: 3.5, category: 'Walking', label: 'Walking, for pleasure, work break' },
  { value: '17260', mets: 4.8, category: 'Walking', label: 'Walking, grass track' },
  { value: '17262', mets: 4.5, category: 'Walking', label: 'Walking, normal pace, plowed field or sand' },
  { value: '17270', mets: 4.0, category: 'Walking', label: 'Walking, to work or class (Taylor Code 015)' },
  { value: '17280', mets: 2.5, category: 'Walking', label: 'Walking, to and from an outhouse' },
  { value: '17302', mets: 4.8, category: 'Walking', label: 'Walking, for exercise, 3.5 to 4 mph, with ski poles, Nordic walking, level, moderate pace' },
  { value: '17305', mets: 9.5, category: 'Walking', label: 'Walking, for exercise, 5.0 mph, with ski poles, Nordic walking, level, fast pace' },
  { value: '17310', mets: 6.8, category: 'Walking', label: 'Walking, for exercise, with ski poles, Nordic walking, uphill' },
  { value: '17320', mets: 6.0, category: 'Walking', label: 'Walking, backwards, 3.5 mph, level' },
  { value: '17325', mets: 8.0, category: 'Walking', label: 'Walking, backwards, 3.5 mph, uphill, 5% grade' },
  { value: '18010', mets: 2.5, category: 'Water Activities', label: 'Boating, power, driving' },
  { value: '18012', mets: 1.3, category: 'Water Activities', label: 'Boating, power, passenger, light' },
  { value: '18020', mets: 4.0, category: 'Water Activities', label: 'Canoeing, on camping trip (Taylor Code 270)' },
  { value: '18025', mets: 3.3, category: 'Water Activities', label: 'Canoeing, harvesting wild rice, knocking rice off the stalks' },
  { value: '18030', mets: 7.0, category: 'Water Activities', label: 'Canoeing, portaging' },
  { value: '18040', mets: 2.8, category: 'Water Activities', label: 'Canoeing, rowing, 2.0-3.9 mph, light effort' },
  { value: '18050', mets: 5.8, category: 'Water Activities', label: 'Canoeing, rowing, 4.0-5.9 mph, moderate effort' },
  { value: '18060', mets: 12.5, category: 'Water Activities', label: 'Canoeing, rowing, kayaking, competition, >6 mph, vigorous effort' },
  { value: '18070', mets: 3.5, category: 'Water Activities', label: 'Canoeing, rowing, for pleasure, general (Taylor Code 250)' },
  { value: '18080', mets: 12.0, category: 'Water Activities', label: 'Canoeing, rowing, in competition, or crew or sculling (Taylor Code 260)' },
  { value: '18090', mets: 3.0, category: 'Water Activities', label: 'Diving, springboard or platform' },
  { value: '18100', mets: 5.0, category: 'Water Activities', label: 'Kayaking, moderate effort' },
  { value: '18110', mets: 4.0, category: 'Water Activities', label: 'Paddle boat' },
  { value: '18120', mets: 3.0, category: 'Water Activities', label: 'Sailing, boat and board sailing, windsurfing, ice sailing, general (Taylor Code 235)' },
  { value: '18130', mets: 4.5, category: 'Water Activities', label: 'Sailing, in competition' },
  { value: '18140', mets: 3.3, category: 'Water Activities', label: 'Sailing, Sunfish/Laser/Hobby Cat, Keel boats, ocean sailing, yachting, leisure' },
  { value: '18150', mets: 6.0, category: 'Water Activities', label: 'Skiing, water or wakeboarding (Taylor Code 220)' },
  { value: '18160', mets: 7.0, category: 'Water Activities', label: 'Jet skiing, driving, in water' },
  { value: '18180', mets: 15.8, category: 'Water Activities', label: 'Skindiving, fast' },
  { value: '18190', mets: 11.8, category: 'Water Activities', label: 'Skindiving, moderate' },
  { value: '18200', mets: 7.0, category: 'Water Activities', label: 'Skindiving, scuba diving, general (Taylor Code 310)' },
  { value: '18210', mets: 5.0, category: 'Water Activities', label: 'Snorkeling (Taylor Code 310)' },
  { value: '18220', mets: 3.0, category: 'Water Activities', label: 'Surfing, body or board, general' },
  { value: '18222', mets: 5.0, category: 'Water Activities', label: 'Surfing, body or board, competitive' },
  { value: '18225', mets: 6.0, category: 'Water Activities', label: 'Paddle boarding, standing' },
  { value: '18230', mets: 9.8, category: 'Water Activities', label: 'Swimming laps, freestyle, fast, vigorous effort' },
  { value: '18240', mets: 5.8, category: 'Water Activities', label: 'Swimming laps, freestyle, front crawl, slow, light or moderate effort' },
  { value: '18250', mets: 9.5, category: 'Water Activities', label: 'Swimming, backstroke, general, training or competition' },
  { value: '18255', mets: 4.8, category: 'Water Activities', label: 'Swimming, backstroke, recreational' },
  { value: '18260', mets: 10.3, category: 'Water Activities', label: 'Swimming, breaststroke, general, training or competition' },
  { value: '18265', mets: 5.3, category: 'Water Activities', label: 'Swimming, breaststroke, recreational' },
  { value: '18270', mets: 13.8, category: 'Water Activities', label: 'Swimming, butterfly, general' },
  { value: '18280', mets: 10.0, category: 'Water Activities', label: 'Swimming, crawl, fast speed, ~75 yards/minute, vigorous effort' },
  { value: '18290', mets: 8.3, category: 'Water Activities', label: 'Swimming, crawl, medium speed, ~50 yards/minute, vigorous effort' },
  { value: '18300', mets: 6.0, category: 'Water Activities', label: 'Swimming, lake, ocean, river (Taylor Codes 280, 295)' },
  { value: '18310', mets: 6.0, category: 'Water Activities', label: 'Swimming, leisurely, not lap swimming, general' },
  { value: '18320', mets: 7.0, category: 'Water Activities', label: 'Swimming, sidestroke, general' },
  { value: '18330', mets: 8.0, category: 'Water Activities', label: 'Swimming, synchronized' },
  { value: '18340', mets: 9.8, category: 'Water Activities', label: 'Swimming, treading water, fast, vigorous effort' },
  { value: '18350', mets: 3.5, category: 'Water Activities', label: 'Swimming, treading water, moderate effort, general' },
  { value: '18352', mets: 2.3, category: 'Water Activities', label: 'Tubing, floating on a river, general' },
  { value: '18355', mets: 5.5, category: 'Water Activities', label: 'Water aerobics, water calisthenics' },
  { value: '18360', mets: 10.0, category: 'Water Activities', label: 'Water polo' },
  { value: '18365', mets: 3.0, category: 'Water Activities', label: 'Water volleyball' },
  { value: '18366', mets: 9.8, category: 'Water Activities', label: 'Water jogging' },
  { value: '18367', mets: 2.5, category: 'Water Activities', label: 'Water walking, light effort, slow pace' },
  { value: '18368', mets: 4.5, category: 'Water Activities', label: 'Water walking, moderate effort, moderate pace' },
  { value: '18369', mets: 6.8, category: 'Water Activities', label: 'Water walking, vigorous effort, brisk pace' },
  { value: '18370', mets: 5.0, category: 'Water Activities', label: 'Whitewater rafting, kayaking, or canoeing' },
  { value: '18380', mets: 5.0, category: 'Water Activities', label: 'Windsurfing, not pumping for speed' },
  { value: '18385', mets: 11.0, category: 'Water Activities', label: 'Windsurfing or kitesurfing, crossing trial' },
  { value: '18390', mets: 13.5, category: 'Water Activities', label: 'Windsurfing, competition, pumping for speed' },
  { value: '19005', mets: 7.5, category: 'Winter Activities', label: 'Dog sledding, mushing' },
  { value: '19006', mets: 2.5, category: 'Winter Activities', label: 'Dog sledding, passenger' },
  { value: '19010', mets: 6.0, category: 'Winter Activities', label: 'Moving ice house, set up/drill holes' },
  { value: '19011', mets: 2.0, category: 'Winter Activities', label: 'Ice fishing, sitting' },
  { value: '19018', mets: 14.0, category: 'Winter Activities', label: 'Skating, ice dancing' },
  { value: '19020', mets: 5.5, category: 'Winter Activities', label: 'Skating, ice, 9 mph or less' },
  { value: '19030', mets: 7.0, category: 'Winter Activities', label: 'Skating, ice, general (Taylor Code 360)' },
  { value: '19040', mets: 9.0, category: 'Winter Activities', label: 'Skating, ice, rapidly, more than 9 mph, not competitive' },
  { value: '19050', mets: 13.3, category: 'Winter Activities', label: 'Skating, speed, competitive' },
  { value: '19060', mets: 7.0, category: 'Winter Activities', label: 'Ski jumping, climb up carrying skis' },
  { value: '19075', mets: 7.0, category: 'Winter Activities', label: 'Skiing, general' },
  { value: '19080', mets: 6.8, category: 'Winter Activities', label: 'Skiing, cross country, 2.5 mph, slow or light effort, ski walking' },
  { value: '19090', mets: 9.0, category: 'Winter Activities', label: 'Skiing, cross country, 4.0-4.9 mph, moderate speed and effort, general' },
  { value: '19100', mets: 12.5, category: 'Winter Activities', label: 'Skiing, cross country, 5.0-7.9 mph, brisk speed, vigorous effort' },
  { value: '19110', mets: 15.0, category: 'Winter Activities', label: 'Skiing, cross country, >8.0 mph, elite skier, racing' },
  { value: '19130', mets: 15.5, category: 'Winter Activities', label: 'Skiing, cross country, hard snow, uphill, maximum, snow mountaineering' },
  { value: '19135', mets: 13.3, category: 'Winter Activities', label: 'Skiing, cross-country, skating' },
  { value: '19140', mets: 13.5, category: 'Winter Activities', label: 'Skiing, cross-country, biathlon, skating technique' },
  { value: '19150', mets: 4.3, category: 'Winter Activities', label: 'Skiing, downhill, alpine or snowboarding, light effort, active time only' },
  { value: '19160', mets: 5.3, category: 'Winter Activities', label: 'Skiing, downhill, alpine or snowboarding, moderate effort, general, active time only' },
  { value: '19170', mets: 8.0, category: 'Winter Activities', label: 'Skiing, downhill, vigorous effort, racing' },
  { value: '19175', mets: 12.5, category: 'Winter Activities', label: 'Skiing, roller, elite racers' },
  { value: '19180', mets: 7.0, category: 'Winter Activities', label: 'Sledding, tobogganing, bobsledding, luge (Taylor Code 370)' },
  { value: '19190', mets: 5.3, category: 'Winter Activities', label: 'Snow shoeing, moderate effort' },
  { value: '19192', mets: 10.0, category: 'Winter Activities', label: 'Snow shoeing, vigorous effort' },
  { value: '19200', mets: 3.5, category: 'Winter Activities', label: 'Snowmobiling, driving, moderate' },
  { value: '19202', mets: 2.0, category: 'Winter Activities', label: 'Snowmobiling, passenger' },
  { value: '19252', mets: 5.3, category: 'Winter Activities', label: 'Snow shoveling, by hand, moderate effort' },
  { value: '19254', mets: 7.5, category: 'Winter Activities', label: 'Snow shoveling, by hand, vigorous effort' },
  { value: '19260', mets: 2.5, category: 'Winter Activities', label: 'Snow blower, walking and pushing' },
  { value: '20000', mets: 1.3, category: 'Religious Activities', label: 'Sitting in church, in service, attending a ceremony, sitting quietly' },
  { value: '20001', mets: 2.0, category: 'Religious Activities', label: 'Sitting, playing an instrument at church' },
  { value: '20005', mets: 1.8, category: 'Religious Activities', label: 'Sitting in church, talking or singing, attending a ceremony, sitting, active participation' },
  { value: '20010', mets: 1.3, category: 'Religious Activities', label: 'Sitting, reading religious materials at home' },
  { value: '20015', mets: 1.3, category: 'Religious Activities', label: 'Standing quietly in church, attending a ceremony' },
  { value: '20020', mets: 2.0, category: 'Religious Activities', label: 'Standing, singing in church, attending a ceremony, standing, active participation' },
  { value: '20025', mets: 1.3, category: 'Religious Activities', label: 'Kneeling in church or at home, praying' },
  { value: '20030', mets: 1.8, category: 'Religious Activities', label: 'Standing, talking in church' },
  { value: '20035', mets: 2.0, category: 'Religious Activities', label: 'Walking in church' },
  { value: '20036', mets: 2.0, category: 'Religious Activities', label: 'Walking, less than 2.0 mph, very slow' },
  { value: '20037', mets: 3.5, category: 'Religious Activities', label: 'Walking, 3.0 mph, moderate speed, not carrying anything' },
  { value: '20038', mets: 4.3, category: 'Religious Activities', label: 'Walking, 3.5 mph, brisk speed, not carrying anything' },
  { value: '20039', mets: 2.0, category: 'Religious Activities', label: 'Walk/stand combination for religious purposes, usher' },
  { value: '20040', mets: 5.0, category: 'Religious Activities', label: 'Praise with dance or run, spiritual dancing in church' },
  { value: '20045', mets: 2.5, category: 'Religious Activities', label: 'Serving food at church' },
  { value: '20046', mets: 2.0, category: 'Religious Activities', label: 'Preparing food at church' },
  { value: '20047', mets: 3.3, category: 'Religious Activities', label: 'Washing dishes, cleaning kitchen at church' },
  { value: '20050', mets: 1.5, category: 'Religious Activities', label: 'Eating at church' },
  { value: '20055', mets: 2.0, category: 'Religious Activities', label: 'Eating/talking at church or standing eating, American Indian Feast days' },
  { value: '20060', mets: 3.3, category: 'Religious Activities', label: 'Cleaning church' },
  { value: '20061', mets: 4.0, category: 'Religious Activities', label: 'General yard work at church' },
  { value: '20065', mets: 3.5, category: 'Religious Activities', label: 'Standing, moderate effort (e.g., lifting heavy objects, assembling at fast rate)' },
  { value: '20095', mets: 4.5, category: 'Religious Activities', label: 'Standing, moderate-to-heavy effort, manual labor, lifting ò 50 lbs, heavy maintenance' },
  { value: '20100', mets: 1.3, category: 'Religious Activities', label: 'Typing, electric, manual, or computer' },
  { value: '21000', mets: 1.5, category: 'Volunteer Activities', label: 'Sitting, meeting, general, and/or with talking involved' },
  { value: '21005', mets: 1.5, category: 'Volunteer Activities', label: 'Sitting, light office work, in general' },
  { value: '21010', mets: 2.5, category: 'Volunteer Activities', label: 'Sitting, moderate work' },
  { value: '21015', mets: 2.3, category: 'Volunteer Activities', label: 'Standing, light work (filing, talking, assembling)' },
  { value: '21016', mets: 2.0, category: 'Volunteer Activities', label: 'Sitting, child care, only active periods' },
  { value: '21017', mets: 3.0, category: 'Volunteer Activities', label: 'Standing, child care, only active periods' },
  { value: '21018', mets: 3.5, category: 'Volunteer Activities', label: 'Walk/run play with children, moderate, only active periods' },
  { value: '21019', mets: 5.8, category: 'Volunteer Activities', label: 'Walk/run play with children, vigorous, only active periods' },
  { value: '21020', mets: 3.0, category: 'Volunteer Activities', label: 'Standing, light/moderate work (e.g., pack boxes, assemble/repair, set up chairs/furniture)' },
  { value: '21025', mets: 3.5, category: 'Volunteer Activities', label: 'Standing, moderate (lifting 50 lbs., assembling at fast rate)' },
  { value: '21030', mets: 4.5, category: 'Volunteer Activities', label: 'Standing, moderate/heavy work' },
  { value: '21035', mets: 1.3, category: 'Volunteer Activities', label: 'Typing, electric, manual, or computer' },
  { value: '21040', mets: 2.0, category: 'Volunteer Activities', label: 'Walking, less than 2.0 mph, very slow' },
  { value: '21045', mets: 3.5, category: 'Volunteer Activities', label: 'Walking, 3.0 mph, moderate speed, not carrying anything' },
  { value: '21050', mets: 4.3, category: 'Volunteer Activities', label: 'Walking, 3.5 mph, brisk speed, not carrying anything' },
  { value: '21055', mets: 3.5, category: 'Volunteer Activities', label: 'Walking, 2.5 mph slowly and carrying objects less than 25 lbs' },
  { value: '21060', mets: 4.5, category: 'Volunteer Activities', label: 'Walking, 3.0 mph moderately and carrying objects less than 25 lbs, pushing something' },
  { value: '21065', mets: 4.8, category: 'Volunteer Activities', label: 'Walking, 3.5 mph, briskly and carrying objects less than 25 lbs' },
  { value: '21070', mets: 3.0, category: 'Volunteer Activities', label: 'Walk/stand combination, for volunteer purposes' }
].sort((a, b) => a.label.localeCompare(b.label));
