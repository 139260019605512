export const types = {
  saveProfile: 'SAVE_PROFILE',
  createProfile: 'CREATE_PROFILE',
  updateProfile: 'UPDATE_PROFILE',
  resetDefaultProfile: 'RESET_DEFAULT_PROFILE',
  deleteProfile: 'DELETE_PROFILE',
  setCurrentProfile: 'SET_CURRENT_PROFILE',
  updateSettings: 'UPDATE_SETTINGS',
  saveExercise: 'SAVE_EXERCISE',
  removeExercise: 'REMOVE_EXERCISE',
  copyExercise: 'COPY_EXERCISE'
};
