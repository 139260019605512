const Activity = {
  none: 'none',
  sedentary: 'sedentary',
  light: 'light',
  moderate: 'moderate',
  very: 'very',
  extremely: 'extremely'
};

export default Activity;
