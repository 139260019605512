import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from './store/global-store';
import { HelmetProvider } from 'react-helmet-async';
import App from './app';
import 'react-datepicker/dist/react-datepicker.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider>
      <HelmetProvider>
        <App/>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
