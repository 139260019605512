import Activity from '../enums/activity';
import Equation from '../enums/equation';
import { generateId } from './utils';
import { t } from './translator';

const localStorageKey = '_jtwebb_wellness';

export const getData = () => {
  try {
    return JSON.parse(localStorage.getItem(localStorageKey));
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const saveData = (data) => {
  return localStorage.setItem(localStorageKey, JSON.stringify(data));
};

export const getDefaultProfile = () => {
  return getData().profiles.find((d) => d.isDefault);
};

export const saveProfile = (profile) => {
  const data = getData().profiles;
  const index = data.findIndex((d) => d.id === profile.id);

  if (!profile.id) {
    // new profile
    profile.id = generateId();
  }

  if (index > -1) {
    data.splice(index, 1, profile);
  } else {
    data.push(profile);
  }

  saveData(data);

  return { ...profile };
};

export const getCurrentProfile = () => {
  const data = getData().profiles;
  return data.find((d) => d.isCurrent);
};

export const getProfileById = (id) => {
  const data = getData().profiles;
  return data.find((d) => d.id === id);
};

export const removeProfile = (id) => {
  const data = getData().profiles;
  const index = data.findIndex((d) => d.id === id);
  const isDefault = data[index].isDefault;
  const isCurrent = data[index].isCurrent;

  // if default, reset
  if (isDefault) {
    data.splice(index, 1, { ...getProfile(t('default'), data[index].isCurrent, true), id });
  }

  // if not default but is current, remove and set current to index 0
  if (isCurrent && !isDefault) {
    data.splice(index, 1);
    data[0].isCurrent = true;
  }

  // if not default and is not current, remove
  if (!isCurrent && !isDefault) {
    data.splice(index, 1);
  }

  saveData(data);
};

export const getProfile = (name = t('newProfile'), isCurrent, isDefault = true) => ({
  age: 0,
  bodyFat: '',
  dailyCalories: 0,
  exercises: [],
  activity: Activity.sedentary,
  equation: Equation.average,
  gender: 'male',
  goalBodyFat: '',
  goalDate: '',
  goalWeight: 0,
  startWeight: 0,
  height: 0,
  isCurrent,
  isDefault,
  name,
  showAdvanced: false,
  startDate: '',
  weeklyPercentLoss: 0,
  weeklyPoundLoss: 0,
  weight: 0
});
