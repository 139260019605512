import React, { createElement } from 'react';
import { phrases } from '../locales/en';
import Polyglot from 'node-polyglot';
import { formatIfNumber } from './utils';

let translator;

const getTranslator = () => {
  if (!translator) {
    translator = new Polyglot({ phrases });
  }

  return translator;
};

export const t = (key, values = {}, ignoreFormatting) => {
  if (!ignoreFormatting && typeof values === 'object' && values !== null) {
    values = Object.keys(values).reduce((acc, current) => {
      acc[current] = formatIfNumber(values[current]);
      return acc;
    }, {});
  }

  const translated = getTranslator().t(key, values);

  if (/<[a-z][\s\S]*>/i.test(translated)) {
    return <Translator translated={translated} tag={'span'} />;
  }

  return translated;
};

const Translator = ({ translated, tag }) => {
  return createElement(tag, { dangerouslySetInnerHTML: { __html: translated } });
};
