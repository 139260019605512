import { generateId } from './utils';
import { activities } from '../data/activities';

export default function getNewExercise() {
  return {
    id: generateId(),
    days: 0,
    duration: 0,
    label: activities[0].label,
    mets: activities[0].mets,
    value: activities[0].value
  };
}
