import { getData, saveData, generateId, getNewProfile, t } from '../helpers';

export const getInitialState = () => {
  let initialData = getData();

  // New user
  if (!initialData) {
    const defaultProfile = getNewProfile({
      name: t('default'),
      isDefault: true,
      id: generateId(),
      isCurrent: true
    });

    initialData = {
      settings: {},
      profiles: [defaultProfile],
      currentProfile: defaultProfile,
      defaultProfile
    };

    saveData({ settings: initialData.settings, profiles: initialData.profiles });
  } else {
    initialData.currentProfile = initialData.profiles.find((p) => p.isCurrent);
    initialData.defaultProfile = initialData.profiles.find((p) => p.isDefault);
  }

  return initialData;
};
