import { t } from './translator';

// https://github.com/ai/nanoid/blob/main/nanoid.js
const urlAlphabet = 'ModuleSymbhasOwnPr-0123456789ABCDEFGHNRVfgctiUvz_KqYTJkLxpZXIjQW';
export const generateId = (size = 21) => {
  let id = '';
  const bytes = crypto.getRandomValues(new Uint8Array(size));
  while (size--) {
    id += urlAlphabet[bytes[size] & 63];
  }
  return id;
};

export const addTime = (date, weeks = 1, days = 0) => {
  const newDate = new Date(date.getTime());
  newDate.setDate(newDate.getDate() + (weeks * 7 + days));
  return newDate;
};

export const formatDate = (date) => {
  return date.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' });
};

export const formatNumber = (number) => {
  return Number(number).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const formatIfNumber = (value) => {
  if (!isNaN(value) && !isNaN(Number(value))) {
    return formatNumber(Number(value));
  }

  return value;
};

export const camelToTitle = (value) => {
  value = value.replace(/([A-Z])/g, ' $1');
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

export const toTitleCase = (value) => {
  return value.replace(
    /\w\S*/g,
    (match) => `${match.charAt(0).toUpperCase()}${match.substring(1)}`
  );
};

export const toKilograms = (pounds) => {
  return pounds * 0.45359237;
};

export const fromKilograms = (kilograms) => {
  return kilograms * 2.20462;
};

export const toCentimeters = (inches) => {
  return inches * 2.54;
};

export const getNewProfile = (opts) => {
  const profile = {
    age: null,
    bodyFat: null,
    dailyCalories: null,
    exercises: [],
    activity: 'sedentary',
    equation: 'average',
    gender: 'male',
    goalBodyFat: null,
    goalDate: null,
    goalWeight: null,
    height: null,
    isCurrent: false,
    isDefault: false,
    name: t('newProfile'),
    startDate: null,
    weeklyPercentLoss: null,
    weeklyPoundLoss: null,
    weight: null,
    startWeight: null
  };

  return { ...profile, ...opts };
};

// https://github.com/bjoerge/debounce-promise/blob/master/index.js
export const debouncePromise = (func, wait = 300) => {
  let deferred;
  let timer;
  let pendingArgs = [];

  return function debounced(...args) {
    const currentWait = typeof wait === 'function' ? wait() : wait;

    if (deferred) {
      clearTimeout(timer);
    } else {
      deferred = defer();
    }

    pendingArgs.push(args);
    timer = setTimeout(flush.bind(this), currentWait);

    return deferred.promise;
  };

  function flush() {
    const thisDeferred = deferred;
    clearTimeout(timer);

    Promise
      .resolve(func.apply(this, pendingArgs[pendingArgs.length - 1]))
      .then(thisDeferred.resolve, thisDeferred.reject);

    pendingArgs = [];
    deferred = null;
  }

  function defer() {
    const deferred = {};

    deferred.promise = new Promise((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });

    return deferred;
  }
};
