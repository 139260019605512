import React, { useContext, useCallback, useReducer, createContext } from 'react';
import rootReducer, { initialState } from './reducers';

const GlobalStore = createContext({ state: {}, dispatch: () => {} });

const asyncer = (dispatch, state) => (action) => {
  return typeof action === 'function' ? action(dispatch, state) : dispatch(action);
};

export const useGlobalStore = () => useContext(GlobalStore);

export const Provider = ({ children }) => {
  const [state, dispatchBase] = useReducer(rootReducer, initialState);

  const dispatch = useCallback(asyncer(dispatchBase, state), []);

  return (
    <GlobalStore.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStore.Provider>
  );
};
