import { generateId, saveData, getData, getNewProfile } from '../helpers';

const findDefault = (data) => {
  return data.profiles.find((p) => p.isDefault);
};

const findCurrent = (data) => {
  return data.profiles.find((p) => p.isCurrent);
};

const afterEachProfileChange = (state, data) => {
  saveData(data);
  const currentProfile = findCurrent(data);
  const defaultProfile = findDefault(data);

  return { ...state, ...data, currentProfile, defaultProfile };
};

export const saveSettings = (state, settings) => {
  const data = getData();
  data.settings = settings;
  return afterEachProfileChange(state, data);
};

export const saveProfile = (state, profile) => {
  if (!profile.isNew) {
    return updateProfile(state, profile);
  }

  delete profile.isNew;
  return createProfile(state, profile);
};

export const createProfile = (state, profile) => {
  const data = getData();
  data.profiles.push(profile);

  return afterEachProfileChange(state, data);
};

export const updateProfile = (state, profile) => {
  const data = getData();
  const index = data.profiles.findIndex((d) => d.id === profile.id);

  if (index === -1) {
    return state;
  }

  data.profiles.splice(index, 1, { ...data.profiles[index], ...profile });

  return afterEachProfileChange(state, data);
};

export const resetDefaultProfile = (state) => {
  const data = getData();
  let index = data.profiles.findIndex((d) => d.isDefault);
  // We should have a default. If we don't, set the first profile as the default
  index = index === -1 ? 0 : index;
  const { id, isCurrent } = data.profiles[index];
  data.profiles[index] = getNewProfile({ id, isCurrent, isDefault: true });

  return afterEachProfileChange(state, data);
};

export const deleteProfile = (state, profileOrId) => {
  const data = getData();
  const id = profileOrId.id || profileOrId;
  const index = data.profiles.findIndex((d) => d.id === id);

  if (index === -1) {
    return state;
  }

  if (data.profiles[index].isDefault) {
    return resetDefaultProfile(state);
  }

  data.profiles.splice(index, 1);

  return afterEachProfileChange(state, data);
};

export const setCurrentProfile = (state, profileOrId) => {
  const data = getData();
  const id = profileOrId.id || profileOrId;

  data.profiles = data.profiles.map((p) => {
    p.isCurrent = p.id === id;
    return p;
  });

  return afterEachProfileChange(state, data);
};

export const updateSettings = (state, settings) => {
  const data = getData();
  data.settings = { ...data.settings, ...settings };

  saveData(data);

  return { ...state, ...data };
};

export const saveExercise = (state, { id, exercise }) => {
  const data = getData();
  const index = data.profiles.findIndex((p) => p.id === id);
  exercise.id = exercise.id || generateId();
  const exerciseIndex = data.profiles[index].exercises.findIndex((e) => e.id === exercise.id);

  if (exerciseIndex === -1) {
    data.profiles[index].exercises.push(exercise);
  } else {
    data.profiles[index].exercises[exerciseIndex] = exercise;
  }

  return afterEachProfileChange(state, data);
};

export const removeExercise = (state, { id, exercise }) => {
  const data = getData();
  const index = data.profiles.findIndex((p) => p.id === id);
  const exerciseIndex = data.profiles[index].exercises.findIndex((e) => e.id === exercise.id);
  data.profiles[index].exercises.splice(exerciseIndex, 1);

  return afterEachProfileChange(state, data);
};

export const copyExercise = (state, { id, exercise }) => {
  const data = getData();
  const index = data.profiles.findIndex((p) => p.id === id);
  data.profiles[index].exercises.push({ ...exercise, id: generateId() });

  return afterEachProfileChange(state, data);
};
