import { types } from './types';
import { getInitialState } from './initial-state';
import * as handlers from './handlers';

export const initialState = getInitialState();

const rootReducer = (state = initialState, { type, payload }) => {
  const actionHandlers = {
    [types.saveProfile]: handlers.saveProfile,
    [types.createProfile]: handlers.createProfile,
    [types.deleteProfile]: handlers.deleteProfile,
    [types.setCurrentProfile]: handlers.setCurrentProfile,
    [types.resetDefaultProfile]: handlers.resetDefaultProfile,
    [types.updateProfile]: handlers.updateProfile,
    [types.updateSettings]: handlers.updateSettings,
    [types.saveExercise]: handlers.saveExercise,
    [types.removeExercise]: handlers.removeExercise,
    [types.copyExercise]: handlers.copyExercise
  };

  if (!actionHandlers[type]) {
    return state;
  }

  return actionHandlers[type](state, payload);
};

export default rootReducer;
